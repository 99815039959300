import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.span`
  display: block;
`

class AlignText extends React.Component {
  static defaultProps = {
    align: 'center',
  }

  getStyle() {
    return {
      textAlign: this.props.align,
    };
  }

  render() {
    return (
      <Wrapper className="AlignText" style={this.getStyle()}>
        {this.props.children}
      </Wrapper>
    )
  }
}

export default AlignText
