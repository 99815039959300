import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  color: #ffffff;

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-left: 0;
    // margin-top: 1em;
  }
`

const Box = styled.div`
  display: flex;
  width: min-content;
  padding: 0;
  text-decoration: none;
  flex-wrap: wrap;
  min-height: 280px;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column-reverse;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 90%;
  }
`

const Description = styled.div`
  p {
    line-height: 175%;
    padding-bottom: 20px;
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    .styled-block {
      text-align: left!important;
    }
    .num-color {
      text-indent: -37px!important;
      margin-bottom: -4px!important;
    }
  }
  
  padding: 0!important;
  .num-color {
    padding: 0!important;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 18px;
    }
    margin-left: 25px;
      @media (max-width: ${props => props.theme.responsive.small}) {
        margin-left: 45px;
      }
      * {
        margin: 0!important;
      }
    }
`

const BlockSpace = styled.div`
  width: 515px;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 320px;
  }
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 0;
  }
  
`


const DescriptionBlock = props => {
  return (
    <Box id={props.slug}
          direction={props.direction}
      >
      <BlockSpace>
      </BlockSpace>

      <Content direction={props.direction}>
        <>
          {props.body ? (
            <Description
              direction={props.direction}
              dangerouslySetInnerHTML={{
                __html: props.body.childMarkdownRemark.html,
              }}
            />
          ) : null}
        </>
      </Content>
    </Box>
  )
}

export default DescriptionBlock
