import Img from 'gatsby-image'
import { navigate } from 'gatsby-link'
import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import styled from 'styled-components'

const Title = styled.span`
  font-family: ${props => props.theme.fonts.menuFont.family};
  font-weight: ${props => props.theme.fonts.menuFont.weight};
  font-size: .875em; // 14px 
  text-transform: uppercase;
  transition: 0.2s;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  text-align: center;
  padding: .8em 0;
`

const TopImage = styled.span`
  width: 100%;
  height: 120px;
`

const Content = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1em;
  & > div {
    margin: .5em 0 0;
    p {
      font-size: .945em; // 15px
      text-transform: none;
    }
  }
`

const Box = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;  
  max-width: 170px;  
  width: 100%;
  overflow: hidden;
  padding: .5em .5em;
  background-color: white;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  cursor: pointer;
`

const LearnMoreLink = styled.span`
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.topMenuFont.family};
  text-transform: uppercase;
  svg {
    margin-left:.1em; 
    margin-top:-3px;   
  }
`

class HeaderInfoBox extends React.Component {
  render() {
    return (
      <Box onClick={() => navigate(this.props.link)}>
        <Title>{this.props.title}</Title>
         <TopImage><Img fixed={this.props.fixedImage} /></TopImage>
        <Content>
          <div>{this.props.children}</div>
          <LearnMoreLink onClick={ () => navigate(this.props.link)}>Learn more <FaArrowAltCircleRight /></LearnMoreLink>
        </Content>
      </Box>
    )
  }
}

export default HeaderInfoBox
