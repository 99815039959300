import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { get } from 'lodash'
import React from 'react'
import { FaFacebook, FaMobileAlt, FaYoutube, FaTwitter, FaInstagram } from 'react-icons/fa'
import styled from 'styled-components'
import Phone from '../../Phone'
import HeaderInfoBox from './HeaderInfoBox'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.white};
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  height: 4em;
  padding: 0 3em;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 1.5em;
    color: ${props => props.theme.colors.text};
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    height: 3em;
    background-color: #1e2931;
  }
`

const Logo = styled(Img)`
  background-size: 100%;
  margin-top: 88px;
  width: 94px !important;
  height: 115px !important;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    background-image: url(${props => props.img});
    width: 134px !important;
    height: 163px !important;
    margin-top: 36px;
  }
`

const Nav = styled.nav`
  padding: 0.5em 1em;
  display: flex;
  justify-content: flex-end;
  user-select: none;  

  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0;
  }

  a {
    font-family: ${props => props.theme.fonts.menuFont.family};
    font-weight: ${props => props.theme.fonts.menuFont.weight};
    font-size: 20px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s;
    color: ${props => props.theme.colors.menuFontColorDesktop};
    border-bottom: 2px solid transparent;
    &:hover {
      @media (min-width: ${props => props.theme.responsive.medium}) {
        color: ${props => props.theme.colors.menuFontColorDesktop};
        // border-bottom: 2px solid ${props => props.theme.colors.primary};
      }
    }

    @media (max-width: ${props => props.theme.responsive.medium}) {
      color: ${props => props.theme.colors.menuFontColor};
    }
  }

  #navIcon {
    width: 38px;
    height: 24px;
    position: relative;
    top: 0;
    margin: 0 auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 10000;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: white;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
  }

  #navIcon span:nth-child(1) {
    top: 0;
  }

  #navIcon span:nth-child(2),
  #navIcon span:nth-child(3) {
    top: 10px;
  }

  #navIcon span:nth-child(4) {
    top: 20px;
  }

  #navIcon.open span:nth-child(1) {
    top: 10px;
    width: 0;
    left: 50%;
  }

  #navIcon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #navIcon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #navIcon.open span:nth-child(4) {
    top: 10px;
    width: 0;
    left: 50%;
  }

  .navWide {
    display: none;
    margin: 0 auto;

    .wideDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .desktop-border-btm:hover {
        box-shadow: 0 3px #00a1e0;
      } 

        
      & > li {
        align-self: center;
        margin-left: 2em;
        height: 4em;
        display: flex;
        align-items: center;
        position: relative;

      

        & > a {
          height: 2em;
          display: flex;
          align-items: center;
        }

        &:first-child {
          margin-left: 0;
        }

        .fusion-open-submenu {
          display: block;
          z-index: 1;
          top: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          outline: 0;
          background: 0 0;
          min-width: 50px;
          border: none;
          line-height: 15px;
          font-size: 13px;
          border: 0;
          right: 0;
          text-align: center;
          padding-right: 35px;
        }
        .fusion-open-submenu:after {
              display: block;
              /* margin: 0; */
              content: "⌃";
              background: transparent;
              color: white;
              transform: rotate(180deg);
              font-size: 26px;
            }
        ul {
          margin-top: 10px;
          transition-duration: 200ms;
          transition-timing-function: ease-in;
          transition-property: opacity, margin-top, visibility;
        }

        ul:not(.megaMenu) {
          display: none;
          opacity: 0;
          position: absolute;
          background-color: ${props => props.theme.colors.base};
          z-index: 1000;
          top: 4em;
          // padding: 0 0.7em 0.5em;
          width: 250px;
          border-top: 3px solid ${props => props.theme.colors.primary};

          li {
            border-bottom: 1px solid ${props => props.theme.colors.text};
            font-weight: 400;
            border-bottom-color: rgba(75,73,81,0.29);
            color: #ffffff;
            padding: 0;

            &:last-child {
              border-bottom: none;
            }
            a {
              display: block;
              padding: 0.8em 0.5em;
              font-size: 0.9em;
              font-weight: 400;
              &:hover {
                background-color: rgba(75,73,81,0.29);
                // background-color: ${props => props.theme.colors.dropdownNavBg};
                // color: ${props => props.theme.colors.text};
              }
            }
          }
        }

        &:hover {
          > ul {
            display: block;
            opacity: 1;
            margin-top: 0;
          }

          .megaMenu {
            display: block;
            opacity: 1;
            margin-top: 0;
          }
        }
      }

      // for mega menu bgs
      .megaMenu {
        position: absolute;
        background-color: white;
        display: flex;
        visibility: hidden;
        opacity: 0;
        z-index: 1000;
        top: 4em;
        margin-left: -200px;
        padding: 0 1em;
        width: 880px;

        li > div {
          margin: 0.5em 0;
        }

        li:hover > div {
          background-color: ${props => props.theme.colors.dropdownNavBg};
        }
      }
    }
  }

  .navNarrow {
    align-self: center;
    display: flex;
    align-items: center;

    .navNarrowPhone {
      color: white;
      margin-right: 1em;
      font-size: 1.3em;

      svg {
        vertical-align: text-top;
      }

      a {
        color: white;
      }

      @media (max-width: ${props => props.theme.responsive.medium}) {
        display: none;
      }
    }

    .menuIcon,
    .menuText {
      font-size: 1.5em;
    }

    svg {
      cursor: pointer;
    }

    .menuText {
      color: white;
      cursor: pointer;
      display: inline-block;
      margin-left: 0.5em;
      font-family: ${props => props.theme.fonts.h1Font.family};
      font-weight: ${props => props.theme.fonts.h1Font.weight};
      text-transform: uppercase;
    }

    .mobileMenu {
      font-size: 1em;
      visibility: hidden;
      margin-top: 0;
      transition-duration: 200ms;
      transition-timing-function: ease-in;
      transition-property: opacity, margin-top, visibility;
      opacity: 0;
      position: absolute;
      transition: all 0.1s;
      z-index: 1000;
      top: 0;
      left: 0;
      right: 0;
      background-color: ${props => props.theme.colors.dropdownNavBg};
      padding-top: 25px;

      .narrowLinks {
        display: block;
        height: auto;

        a {
          position: relative;
          color: white;
          font-weight: 600;
          &:hover {
            color: ${props => props.theme.colors.primary};
          }
        }

        li {
          display: block;
          margin-left: 0;
          border-bottom: 1px solid ${props => props.theme.colors.grey};
          @media (max-width: ${props => props.theme.responsive.medium}) {
          border-bottom: none;
          // padding: 0 1em;
          &:hover {
            background: black;
            .navToggle {
              display:block;
              background: #262528;
              margin: 0;
            }
          }
        }
          ul {
            display: none;
            // padding: 0 0 1em 1em;

            li {
              border-bottom: none;
              padding: 0.5em 1em;

              a {
                font-size: 1em;
                font-weight: 400;
                padding: .5em 1em;

                &::after {
                  top: 5px;
                }
              }
            }
          }
        }

        a {
          text-decoration: none;
          display: block;
          padding: 0.5em 1em;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    .navWide {
      display: flex;
    }

    .navNarrow {
      display: none;
    }
  }
`

const LinkTag = styled(Link)`
  position: relative;
  color: white;

  &::after {
    content: ${props=> props.subitems ? '〉' : ''};
    position: absolute;
    right: 10px;
    top: 10px;
    transform: rotate(95deg);
  }
`;

const SocialContact = styled.div` 
  padding: 1em 0 1em;
  box-shadow: 0 8px 15px -4px black;
  background: grey;
`

const DownArrow = styled.button`
  transform: rotate(180deg);
  background: 0;
  border: 0;
  color: white;
  font-size: 22px;
  margin: 0 1em 0;
  padding: 0;
  height: 100%;
  outline: none;
`;

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: space-between;

  * {
    color: ${props => props.activeLink? "#00a1e0!important": null};
  }

`;

class NavContents extends React.Component {
  constructor(props) {
    super(props)
    this.onNavClick = this.onNavClick.bind(this)
  }
  // Stop page from jumping on current page links
  onNavClick(event) {
    alert(event)
    event.preventDefault()
    if (event.target.hasAttribute('aria-current')) {
    }
  }

  render() {
    // Get nav data from props
    const { data } = this.props
    // Bail if no data is available
    if (!data) return null
    // Deconstruct the navItems from the data
    const { navItems } = data.contentfulNavigationType
    const pathName = typeof window !== 'undefined' ? window?.location?.pathname : '';

    return (
      <>
        {navItems.map((item, index) => {
          /**
           * Return Mega Menu
           * It contains the sublinks with images and content needed for the mega menu
           * This is a brute force way of driving the logic
           */
          const link =
            item.path || item.url || '/' + get(item, 'pageLink.slug', '')
          if (index === 0)
            return (
              <li key={index} className={this.props.type === 'mobile' ? '': "desktop-border-btm"}>
                <Link to={link}>
                  {item.displayName}
                </Link>
                <ul className={`list--item ${this.props.type === 'mobile' ? '' : 'megaMenu'}`}>
                  {item.subItems &&
                    item.subItems.map((subItem, subItemIndex) => {
                      const subItemlink =
                        subItem.path ||
                        subItem.url ||
                        '/' + get(subItem, 'pageLink.slug', '')
                      return (
                        <li key={subItemIndex} className="list--item">
                          {this.props.type === 'mobile' && (
                            <Link onClick={this.onNavClick} className={`active-link`} to={subItemlink}>
                              {subItem.displayName}
                            </Link>
                          )}
                          {this.props.type !== 'mobile' && (
                            <HeaderInfoBox
                              fixedImage={subItem.image.fixed}
                              title={subItem.displayName}
                              link={subItemlink}
                            >
                              <p>
                                {!subItem
                                  ? null
                                  : subItem.content.childMarkdownRemark
                                      .rawMarkdownBody}
                              </p>
                            </HeaderInfoBox>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </li>
            )
          /**
           * Return the rest of the links from Mega Menu Navigation type
           * Skips the first index and loops through the remaining nav items
           */
          if (index > 0) {
            const isSubItemSelected = (item.subItems === null ? false: item.subItems.filter(item => pathName === `/${item.pageLink.slug}`)[0]);
            return (
              <li key={index} className={`${item?.subItems?.length ? "" : "desktop-border-btm"}`}>
                <MenuItemContainer activeLink={(pathName ===link) || isSubItemSelected} >
                  <LinkTag className={`list-tag`} subitems={item.subItems === null ? 0: item?.subItems.length} to={link}>
                    {item.displayName}
                  </LinkTag>
                  {item.subItems && this.props.type === 'mobile' ? <DownArrow>⌃</DownArrow> : null}
                </MenuItemContainer>

                {/**
                 * Sub Items
                 * If sub items are found then this will loop through those
                 * creating the sub nav menu items
                 */
                !item.subItems ? null : (
                  <ul className={`list--item ${this.props.type === 'mobile' ? 'navToggle' : ''}`} >
                    {item.subItems.map((subItem, subItemIndex) => {
                      const subItemlink =
                        subItem.path ||
                        subItem.url ||
                        '/' + get(subItem, 'pageLink.slug', '')
                      return (
                        <li key={subItemIndex}>
                          <Link to={subItemlink} className={pathName === subItemlink ? 'active-link': ''}>
                            {this.props.type === 'mobile' ? "-" : null} {subItem.displayName}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            )
            }
        })}
        <ContactLink key='contact'>
          <MenuItemContainer activeLink={pathName ==='/contact'}>
            <LinkTag className="link-tag"  to={'/contact'}>
              Contact Us
            </LinkTag>
          </MenuItemContainer>
        </ContactLink>
      </>
    )
  }
}

const ContactLink = styled.li`
  @media (min-width: ${props => props.theme.responsive.medium}) {   
      display: none !important;
  }
`

const SocialMediaLinks = styled.div`
  display: flex;
  justify-content: space-evenly;
  >a {
    svg {
      background: grey;
      border-radius: 50px;
      padding: 3px;
      width: 35px;
      height: 35px;
      fill: ${(props) => props.theme.colors.base};
    }
  }
  `

class NavComponent extends React.Component {
  burgerToggle() {
    let mobileMenu = document.querySelector('.mobileMenu')
    let navIcon = document.getElementById('navIcon')
    if (mobileMenu.style.visibility === 'visible') {
      mobileMenu.style.visibility = 'hidden'
      mobileMenu.style.marginTop = '0'
      mobileMenu.style.top = '0'
      mobileMenu.style.opacity = '0'
      navIcon.classList.remove('open')
    } else {
      mobileMenu.style.visibility = 'visible'
      mobileMenu.style.opacity = '1'
      mobileMenu.style.marginTop = '0'
      navIcon.classList.add('open')
    }
  }

  render() {
    const { data } = this.props;
    return (
      <Nav >
        <div className="navWide">
          <ul className="wideDiv">
            <NavContents data={data} />
          </ul>
        </div>
        <div className="navNarrow">
          <div className="navNarrowPhone">
            <Phone>
              <FaMobileAlt />
            </Phone>
          </div>
          <div id="navIcon" onClick={this.burgerToggle}>
            <span />
            <span />
            <span />
            <span />
          </div>
          <div onClick={this.burgerToggle} className="menuText" />
          <div className="mobileMenu">
            <ul className="narrowLinks">
              <NavContents data={data} type="mobile" />
            </ul>
            <SocialContact>
              <SocialMediaLinks>
                <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/user/SkidCarSystem" >
                  <FaYoutube />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/SkidcarSystemUSA/">
                  <FaFacebook />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/skidcarsystemusa/?hl=en">
                  <FaInstagram />
                </a>
              </SocialMediaLinks>
            </SocialContact>
          </div>
        </div>
      </Nav>
    )
  }
}

/**
 * Menu uses StaticQuery to get the navitems from contentful
 * The Menu component passes the data to the NavComponent via props
 * Navigation Type and Item data is then passed to subsequent
 * components as neede via props.
 */
class Menu extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulNavigationType(slug: { eq: "top-navigation" }) {
              slug
              navItems {
                ...navItem
              }
            }
            logo: file(relativePath: { eq: "skidcar-logo.png" }) {
              childImageSharp {
                fixed(width: 169) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <Wrapper>
              <Link style={{ alignSelf: 'center' }} to="/">
                <Logo
                  fixed={data.logo.childImageSharp.fixed}
                  alt="SKIDCAR System logo"
                />
              </Link>
              <NavComponent data={data} />
            </Wrapper>
          )
        }}
      />
    )
  }
}

export default Menu
