import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.span`
  position: relative;
  padding-bottom: 56.25%;
  min-height: 100%;
  overflow: hidden;
  max-width: 100%;
  display: block;
 
  & iframe, & object, & embed { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .grid-fixed-table{
    width:100%;
  }

  
  &.blockscroll iframe {
    pointer-events: none;
  }
`

class ResponsiveIframe extends React.Component {

  constructor( props ){
    super( props );
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.target.classList.remove("blockscroll");
  }

  render() {
    return (
      <Wrapper className={`${this.props.blockscroll ? 'blockscroll' : ''} ResponsiveIframe`} onClick={this.handleClick}>
          <iframe  {...this.props} src={this.props.src} frameBorder='0' height="100%" allowFullScreen ></iframe>
      </Wrapper>
    )
  }
}

export default ResponsiveIframe
