import Img from 'gatsby-image';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import SubTitleBlue from './SubTitleBlue';
import { renderAst } from './utils/render-fns';

const Title = styled.span`
  font-family: ${props => props.theme.fonts.h3Font.family};
  font-weight: 600;
  font-size: 65px;
  text-transform: uppercase;
  transition: 0.2s;
  margin: 0 auto;
  line-height: 125%;
  text-align: center;
  color: ${props => props.theme.colors.black};

  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 35px;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-align: center;
  }
`

const Body = styled.div`
  text-align: center;
  margin-top: 20px;

  .phone-no {
    color: #707070;
    font-size: 1.3em;
    white-space: nowrap;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 18px;
      line-height: 28px;
      color: #707070;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      text-align: center;
    }
  }


  p {
    line-height: 40px;
    font-size: 22px;
    font-weight: 500
    color: ${props => props.theme.colors.base};
    
    @media (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      text-align: center;
    }
  }
`

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Image = styled.div`  
  width: 100%;
  min-height: 300px;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    min-height: unset;
  }
`

const PrimaryColorLine = styled.div`
  height: 6px;
  width: 275px;
  background-color: ${props => props.theme.colors.primary};
  margin-top: 20px;
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 70%;
  }
`

const FormBioBox = props => {
  const bodyHtml = get(props, 'body.childMarkdownRemark.htmlAst', { type: 'root', children: [] })

  return (
    <Container>
      <SubTitleBlue>{props.subTitle ? props.subTitle : null}</SubTitleBlue>
      <Title>{props.displayTitle ? props.displayTitle : null}</Title>
      { props.displayTitle ? <PrimaryColorLine></PrimaryColorLine> : null}
      <Body>{renderAst(bodyHtml)}</Body>
      {props.image ? <Image><Img fluid={props.image.fluid} /></Image> : null }
    </Container>
  )
}

export default FormBioBox
