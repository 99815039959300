import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 600;  
  text-transform: uppercase;
  transition: 0.2s;
  color: #404040;
  text-decoration: none;
  display: block;

  
  &.largeTitle {
    font-size: 34px;
    font-family: 'oswald',sans-serif;
    font-weight: 400;
    text-align: center;   
    color: #ffffff; 
  }
  
  @media (max-width: ${props => props.theme.responsive.large}) {
    font-size: 1.75em;
  }
  
`


const Content = styled.div`
  padding: 1.5em;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  
  &.iconAlignment {
    display: block;
  }


  p{
    color: white;
    line-height: 175%;
    text-align: center;
    margin: 15px;
  }
`

const IconBoxDescriptionContainer = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;  
  max-width: 363px;
  width: 100%;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  // margin: 15px;
  
  @media (max-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 2em;
    
    // last 2 items in bottom row remove bottom margins (assumes always 2 cols )  
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
    
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    max-width: none;  
    height: auto;
    
    // last 2 items in bottom add back bottom margins (assumes always 2 cols )  
    &:nth-last-child(-n+2) {
      margin-bottom: 2em;
    }
    
    // last item remove bottom margin to override above
    &:last-child {
      margin-bottom: 0;
    }
  }
`


const IconBoxDescription = props => {
  let bgimg = null;
  if (props.image) {
    bgimg = props.image;
  }
  if (typeof bgimg === 'object' && bgimg.fluid) {
    bgimg = bgimg.fluid;
  }

  const title = props.title;
  const Logo = styled(Img)`
    max-width: 108px!important;
    max-height: 108px!important;
    width: 100%;
    object-fit: scale-down;
    object-position: bottom;
    margin: auto;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    max-width: 88px!important;
    // max-height: 80px!important;
  }

`
const body = !props.body ? null : props.body.childMarkdownRemark.rawMarkdownBody;

  
  return (
    <IconBoxDescriptionContainer id={props.slug}>
      <div>
        <Logo
              fluid={bgimg}
              alt={props.slug}
            />
      </div>
      <Content className="iconAlignment">
        <div>
          <Title className="largeTitle">{title}</Title>
        </div>

        {body ? <p>{body}</p> : null}

      </Content>
    </IconBoxDescriptionContainer>
  )
}

export default IconBoxDescription
