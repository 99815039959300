import Img from 'gatsby-image'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import LinkButton from './LinkButton'

// NOTE: keep these all inline elements so the a tag is still valid
const Title = styled.div`
  font-size: 42px ;
  color: #404040;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.titleFontface};
  padding-bottom: .5em;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 35px;
  }

`

const Content = styled.div`
  flex: 1;
  padding: ${props => props.direction === 'right'? '0 3em 0 0': '0 3em 0 3.5em' };
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-left: 0;
    margin-top: 1em;
    width: inherit;
    padding: 0;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.direction === 'right'? 'row-reverse': 'row' };
  text-decoration: none;
  flex-wrap: wrap;
  min-height: 280px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin: 20px auto;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 90%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    @media (max-width: ${props => props.theme.responsive.small}) {
      max-width: none;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const ImageContainer = styled.div`
  width: 545px;
  padding-left: ${props => props.direction === 'right'? '1em': '0' };

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
    padding 0;
  }

  `

const ImageCaption = styled.div`
  font-size: 17px;
  line-height: 24px;
  color: ${props => props.theme.colors.base}
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
  margin: 10px;
`;


const ButtonContainer = styled.div`
  min-width: 180px;
  max-width: 252px;
  height: 57px;

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin: auto;
  }
`
const Description = styled.div`

  p {
    line-height: 175%;
    padding-bottom: 20px;
    font-size: 22px;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 18px;
    }

  }
`


const ImageDescriptionBlock = props => {
  const slug = get(props, 'pageLink.slug', null)

  let link = props.learnmore
  if (slug) {
    link = '/' + slug
  }
  return (
    <Box id={props.slug}
          direction={props.direction}
      >
      <ImageContainer direction={props.direction}>
        {props.image ? <Img fluid={props.image.fluid} alt={props.image.title} /> : null}
        {props.imageCaption ? <ImageCaption>{props.imageCaption}</ImageCaption> : null}
      </ImageContainer>
      <Content style={props.direction === 'right' ? ({paddingLeft: 0 }): ({ paddingRight: 0 })}>
        <div>
          {props.title ? <Title>{props.title}</Title> : null}
          {props.body ? (
            <Description
              dangerouslySetInnerHTML={{
                __html: props.body.childMarkdownRemark.html,
              }}
            />
          ) : null}
        </div>

        {link ? (
          <ButtonContainer>
            <LinkButton href="/about">Learn More</LinkButton>
          </ButtonContainer>
        ) : null}
      </Content>
    </Box>
  )
}

export default ImageDescriptionBlock
