export default {
  colors: {
    black: '#404040',
    base: '#171717',
    primary: '#00a1e0',
    secondary: '#f5eddc',
    tertiary: '#D1C098',
    linkColor: '#000000',
    linkHoverColor: '#E8E0D0',
    dropdownNavBg: '#262528',
    buttonBGColor: '#CFAC51',
    buttonBGColorHover: '#5a2149',
    buttonTextColor: '#fff',
    secondaryButtonBGColor: '#5a2149',
    secondaryButtonTextColor: '#FFFFFF',
    tertiaryButtonBGColor: '#f5eddc',
    tertiaryButtonTextColor: '#404040',
    text: '#404040',
    grey: '#B5B5B5',
    textSizerBG: '#5a2149',
    lightPurple: '#BB8EAE',
    menuFontColor: '#404040',
    menuFontColorDesktop: '#fff',
    footerBGColor: '#252524',
    HeaderTextColor: '#868686',
    footerEventTextColor: '#868686',
    footerBootomline: '#4d4d4d',
  },
  slideshows: {
    heroSlideshow: {
      speed: 5000,
      prevNextButtonsScale: '.3s',
      animateTransition: '2s',
      titleDelay: '1.3s',
      titleTransition: '.8s',
      buttonDelay: '1.8s',
      buttonTransition: '.8s',
    },
    contentSlideshow: {
      speed: 5000,
      prevNextButtonsScale: '.3s',
      animateTransition: '2s',
      titleDelay: '1.3s',
      titleTransition: '.8s',
      buttonDelay: '1.6s',
      buttonTransition: '.8s',
    }
  },
  sizes: {
    maxWidth: '1200px',
    maxWidthCentered: '700px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  /* Base font sizes are in REMS */
  fonts: {
    /* The font sizer sizes */
    fontSizes: {
      base0: '16px',
      base1: '18px',
      base2: '20px',
      base3: '24px',
    },
    /* Base font on body tag inherited by others, p etc */
    titleFontface: '\'oswald\', sans-serif',
    footerFontface: '\'oswald\', sans-serif',
    descriptionFontface: 'Montserrat, sans-serif',
    bodyFont: {
      family: '\'Montserrat\', sans-serif',
      weight: '400',
    },
    menuFont: {
      family: '\'Montserrat\', sans-serif',
      weight: '500',
    },
    topMenuFont: {
      family: '\'Montserrat\', sans-serif',
      weight: '700',
    },
    sectionTitleFont: {
      family: '\'oswald\', sans-serif',
      weight: '600',
      color: '#404040',
      size: '65px',
    },
    sectionSubTitleFont: {
      family: '\'oswald\', sans-serif',
      weight: '700',
      color: '#404040',
      size: '45px',
    },
    sectionDescriptionFont: {
      family: '\'Montserrat\', sans-serif',
      weight: '400',
      color: '#404040',
      size: '24px'
    },
    h1Font: {
      family: '\'oswald\', sans-serif',
      weight: '700',
      color: '#404040',
      size: '2.25rem',
    },
    lightFont: {
      family: '\'oswald\', sans-serif',
      weight: '300',
    },
    h2Font: {
      family: '\'oswald\', sans-serif',
      weight: '700',
      color: '#404040',
      size: '1.75rem'
    },
    h3Font: {
      family: '\'oswald\', sans-serif',
      weight: '700',
      color: '#404040',
      size: '1.5rem',
    },
    h4Font: {
      family: '\'oswald\', serif',
      weight: '700',
      color: '#404040',
      size: '1rem',
    },
    pFont: {
      size: '1.125rem',
      lineHeight: '125%',
      marginBottom: '1em',
    },
    buttonFont: {
      family: '\'Montserrat\', sans-serif',
      weight: '600',
    },
    sizerTitleFont: {
      family: '\'Montserrat\', sans-serif',
      weight: '700',
    },
    sizerAlphaFont: {
      family: '\'Montserrat\', serif',
      weight: '700',
    },
  },
}
