import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';


const SectionBlock = styled.div`
  text-align: left;
`

const SectionTitle = styled.div`
  font-size: 45px;
  color: #404040;
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  font-weight: 600;
  padding: 80px 0 41px 0;
  margin:auto;
  
  sup {
    font-weight: 600;
    vertical-align: 17px;
    font-size: 25px;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 600;
    width: 100%;
    line-height: 50px;
    padding-top: 40px;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
`



const LogoWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 30px;
  align-items: center;

    @media (max-width: ${props => props.theme.responsive.small}) {
      flex-direction: row;
      width: 90%;
      // justify-content: space-between;
      align-items: center;
    }

    .description-container {
      font-size: 22px;
      line-height: 31px;
      color: #171717;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      text-align: left;
      margin-left: 30px;
      
      @media (max-width: ${props => props.theme.responsive.medium}) {
        margin-left: 15px;
        font-size: 15px;
      }
    }
`

const Logo = styled(Img)`
  min-width: 81px!important;
  min-height: 81px!important;
  width: 100%;
  object-fit: scale-down;
  object-position: bottom;
  margin: auto;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    min-width: 68px!important;
    min-height: 60px!important;
  }
  `

  const ServiceInquiries = styled.div`
    width: 260px;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      width: auto;
    }
  `

  const AddressLine = styled.div`
    width: 248px;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      width: auto;
    }
  `

const ContactCardBlock = props => {
  const phoneIcon = !props.phoneIcon ? null : props.phoneIcon;
  const tollFreePhone = !props.tollFreePhone ? null : props.tollFreePhone;
  const localPhone = !props.localPhone ? null : props.localPhone;
  const fax = !props.fax ? null : props.fax;

  const emailIcon = !props.emailIcon ? null : props.emailIcon;
  const email = !props.email ? null : props.email;

  const addressLine = !props.addressLine ? null : props.addressLine;
  const addressIcon = !props.addressIcon ? null : props.addressIcon;

  const serviceInquiries = !props.serviceInquiries ? null : props.serviceInquiries;
  const serviceIcon = !props.serviceIcon ? null : props.serviceIcon;

  return (props.headerTitle ?
    <SectionBlock >
      <SectionTitle  dangerouslySetInnerHTML={{ __html: props.headerTitle}} />
      {
        (phoneIcon) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!phoneIcon ? null : <Logo
              fluid={phoneIcon.fluid}
              alt={phoneIcon.title}
            />
            }
          </div>
          <div className="description-container">
            <div dangerouslySetInnerHTML={{ __html: tollFreePhone}} />
            <div dangerouslySetInnerHTML={{ __html: localPhone}} />
            <div dangerouslySetInnerHTML={{ __html: fax}} />
          </div>
        </LogoWrapper>
        : null
      }
      {
        (emailIcon) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!emailIcon ? null : <Logo
              fluid={emailIcon.fluid}
              alt={emailIcon.title}
            />
            }
          </div>
          <div className="description-container">
          <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>{email}</a>
          </div>
        </LogoWrapper>
        : null
      }
      {
        (addressIcon) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!addressIcon ? null : <Logo
              fluid={addressIcon.fluid}
              alt={addressIcon.title}
            />
            }
          </div>
          <div className="description-container">
          <AddressLine dangerouslySetInnerHTML={{ __html: addressLine}} />
          </div>
        </LogoWrapper>
        : null
      }
      {
        (serviceIcon) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!serviceIcon ? null : <Logo
              fluid={serviceIcon.fluid}
              alt={serviceIcon.title}
            />
            }
          </div>
          <div className="description-container">
          <ServiceInquiries dangerouslySetInnerHTML={{ __html: serviceInquiries}} />
          </div>
        </LogoWrapper>
        : null
      }
    </SectionBlock>
    : null)
}


export default ContactCardBlock
