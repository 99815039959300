import React from 'react'
import styled from 'styled-components'
import { renderAst, renderBlock } from './utils/render-fns'
import RoundCutFooter from './RoundCutFooter'

const Wrapper = styled.div`
  margin-bottom: ${props => props.overflowSection ? '-100px' : '0px'};
  padding-top: 1px;
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding-top: 1;
  }

`

const SectionRow = ({section, sectionHeader, slug}) => {
  const overflowSection = section ? section.overflowSection : null;

  return (
    <Wrapper id={slug} slug={slug} overflowSection={overflowSection}>
            {sectionHeader}
            {!section.body ? null : <div style={{ height: '100%' }}>{renderAst(section.body.childMarkdownRemark.htmlAst)}</div>}
            {!section.contentBlock ? null :
              section.contentBlock.map((block, blockIndex) => {
                return renderBlock(block, blockIndex)
            })}
    {section && section.roundCutFooter ? <RoundCutFooter backgroundColor={section && section.backgroundColor}/> : null}
    </Wrapper>
  )
}

export default SectionRow
