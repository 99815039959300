import React from 'react';
import styled from 'styled-components'


const AccordionItemContainer = styled.div`
    margin: 40px auto;

    .accordion__item {
        background: #fff;
        border-radius: 3px;
    }
    .accordion__item:not(:last-child) {
        margin-bottom: 20px;
    }

    .accordion-part__header {
        position: relative;
        cursor: pointer;
        background-color: #fff;
        font-size: 32px;
        color: ${props => props.theme.colors.text};
        font-family: "Oswald", sans-serif;
        font-weight: 600;
        display: flex;
        align-items: center;
        height: auto;
        min-height: 80px;
        padding: 0 1em;

        @media (max-width: ${props => props.theme.responsive.medium}) {
            font-size: 22px;
        }
      
    }
    .accordion-part__header::before {
        content: '+';
        display: block;
        background-size: contain;
        position: relative;
        font-size: 52px;
        line-height: 0;
        margin-top: -5px;
        width: 35px;
        @media (max-width: ${props => props.theme.responsive.medium}) {
            width: 40px;
            font-size: 35px;
        }

    }
    .accordion-part__header_opened {
        background-color: ${props => props.theme.colors.primary};
        color: #fff;
    }
    .accordion-part__header_opened::before {
        content: "-";
        display: block;
        background-size: contain;
        position: relative;
        font-size: 52px;
        line-height: 0;
        width: 35px;
        @media (max-width: ${props => props.theme.responsive.medium}) {
            width: 40px;
            font-size: 35px;
        }
    }
    .accordion-part__text {
        font-size: 22px;
        line-height: 35px;
        color: ${props => props.theme.colors.base};
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        padding: 2em 3em;

        @media (max-width: ${props => props.theme.responsive.medium}) {
            font-size: 18px;
            padding: 1em 1.5em;
            line-height: 27px;
        }

    }

`;

class AccordionItem extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			height: 0
		};
	}

    componentDidMount() {
		window.setTimeout(() => {
			const el = this.node;
			const height = el.querySelector('.panel__inner').scrollHeight;
			this.setState({
				height
			});
		}, 333);
	}

    render() {
        const {accordion, onHeaderClick, isOpen} = this.props;
		const { height } = this.state;
		const innerStyle = {
            height:  `${isOpen ? height : 0}px`,
            transition: '.5s all'
		}
        return (
        <div className='accordion__item accordion-part' ref={(n) => {this.node = n}}>
            <div className={`accordion-part__header ${(isOpen) ? 'accordion-part__header_opened ' : ''}`}
                onClick={onHeaderClick}
            >
                {accordion.question}
            </div>
            <div className={`accordion-part__body panel__inner ${(isOpen) ? 'fade-in' : null} `} aria-hidden={ !isOpen } style={innerStyle}>
                {
                    <div className={`accordion-part__text ${isOpen ? '' : 'visibility-none'}`} dangerouslySetInnerHTML={{__html: accordion.answer?.childMarkdownRemark?.rawMarkdownBody}} />
                }
            </div>
        </div>
        )
    }
}

class AccordionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activeAccordion: 0,
    };
  }

  headerClick = (index) => {
    this.setState(prev => ({
        activeAccordion: prev.activeAccordion === index ? -1 : index,
    }));
  }

  render() {
      const accordionElement = this.props.accordions.map((item, index) =>
           <AccordionItem
                key={index}
                accordion={item}
                isOpen={this.state.activeAccordion === index}
                onHeaderClick={() => this.headerClick(index)}
           />
      );

      return (
          <div className='accordion__list'>
              {accordionElement}
          </div>
      )
  }
}


class AccordionListContainer extends React.Component {
  render() {
      return (
          <AccordionItemContainer className='container'>
              <div className='accordion'>
                  {
                      this.props.blocks ?
                      <AccordionList accordions={this.props.blocks}/>
                  : null}
              </div>
          </AccordionItemContainer>
      )
  }
}

export default AccordionListContainer;
