import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  background-color: ${props => props.bgColor};
  background-image: url(${props => props.bgImage});
  background-position: top;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 100%;
  background-size: ${props => (props.bgImageSizeCover) ? 'cover' : 'contain'};
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    background-size: auto;
  }

  z-index: ${props => props.slug==='service-request-form' ? "" : props.slug==='section-row' ? "2" : '1'};
`

const Container = props => {
  const bgImage = props.section ? props.section.image ? props.section.image.fluid.src : null : null;
  const bgColor = props.section ? props.section.backgroundColor : null;
  const paddingTop = props.section ? props.section.paddingTop : null;
  const slug = props.section ? props.section.slug : null;
  // console.log('slug: ', slug);
  // console.log('props.section.bgImageSizeCover: ', props.section?.bgImageSizeCover);
  // console.log('props.bgImageSizeCover: ', props.bgImageSizeCover);
  const bgImageSizeCover = props.section?.bgImageSizeCover || !props.bgImageSizeCover;
// console.log('bgImageSizeCover: ', bgImageSizeCover);
    return <Wrapper ref={props.ref} slug={slug} bgImageSizeCover={bgImageSizeCover} bgImage={bgImage} bgColor={bgColor} paddingOverride={props.paddingOverride} mobilePadding={props.mobilePadding} paddingTop={paddingTop}>{props.children}</Wrapper>
}

export default Container;
