import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  border-bottom: 20px solid ${props => props.theme.colors.primary};
  
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 331px;
  object-fit: ${props => props.fit || 'cover'};
  object-position: ${props => props.position || '50% 50%'};  
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    height: 345px;    
    object-fit: cover;
    object-position: 88% 0;
  }
`

const HeroWrapper = styled.div`
  box-shadow: inset 0 100px 50px -23px #00000061;
`

const Hero = (props) => {
  return props.img ?
    <HeroWrapper>
      <Wrapper>
        <BgImg fluid={props.img.fluid} alt={props.img.title || "Hero image"}/>
      </Wrapper>
      {/* <Title title={props.title} /> */}
    </HeroWrapper>
    : null
}


export default Hero
