import styled from 'styled-components';

const SubTitleBlue = styled.h6`
  font-size: 18px;
  text-transform: uppercase;  
  position: relative;
  padding-bottom: .2em;
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  letter-spacing: 5px;

  @media (min-width: ${props => props.theme.responsive.small}) and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 18px;
  }
`

export default SubTitleBlue;