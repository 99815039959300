import Img from 'gatsby-image'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import LinkButton from './LinkButton'

// NOTE: keep these all inline elements so the a tag is still valid
const Title = styled.div`
  font-size: 42px ;
  color: #404040;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.titleFontface};
  padding-bottom: .5em;
`

const Content = styled.div`
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  padding-top: ${props => props.direction === 'top' ? "25px" : 0};

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-left: 0;
    // margin-top: 1em;
    // width: inherit;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: ${props => props.direction === 'top' ? 'column' : 'column-reverse'};
  width: min-content;
  padding: 0;
  text-decoration: none;
  flex-wrap: wrap;
  min-height: ${props => props?.data?.length ? "280px" : ""};
  max-width: 1200px;
  // width: 94%;
  margin: auto;
  margin-top: 4%;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 90%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    @media (max-width: ${props => props.theme.responsive.small}) {
      max-width: none;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
  `

const ImageContainer = styled.div`
  width: 515px;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
  }

  `

const ImageCaption = styled.div`
  font-size: 17px;
  line-height: 24px;
  color: ${props => props.theme.colors.base}
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
  margin: 10px;
`;


const ButtonContainer = styled.div`
  min-width: 180px;
  max-width: 252px;
  height: 57px;

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin: auto;
  }
`
const Description = styled.div`
  p {
    line-height: 175%;
    padding-bottom: ${props => props.direction === 'bottom' ? '0' : '20px'};

    @media (max-width: ${props => props.theme.responsive.small}) {
      padding-bottom: 0;
      // margin-bottom: 0;
    }
  }
`


const ImageDescriptionBlockVertical = props => {
  const slug = get(props, 'pageLink.slug', null)

  let link = props.learnmore
  if (slug) {
    link = '/' + slug
  }
  return (
    <Box id={props.slug}
      direction={props.direction}
      data={props.body}
    >
      <ImageContainer>
        {props.image ? <Img fluid={props.image.fluid} alt={props.image.title} /> : null}
        {props.imageCaption ? <ImageCaption>{props.imageCaption}</ImageCaption> : null}
      </ImageContainer>
      <Content direction={props.direction}>
        <div>
          {props.title ? <Title>{props.title}</Title> : null}
          {props.body ? (
            <Description
              direction={props.direction}
              dangerouslySetInnerHTML={{
                __html: props.body.childMarkdownRemark.html,
              }}
            />
          ) : null}
        </div>

        {link ? (
          <ButtonContainer>
            <LinkButton href="/about">Learn More</LinkButton>
          </ButtonContainer>
        ) : null}
      </Content>
    </Box>
  )
}

export default ImageDescriptionBlockVertical
