import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image'

const PhotoWrapper = styled(Img)`
  position: relative;
  width: auto;
  height: 456px;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: ${props => props.fit || 'cover'};
  object-position: ${props => props.position || '50% 50%'};  

  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100vw;
    height: auto;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: auto;
    height: auto;
  }
`;

const Photo = (props) => {
    return (
      <PhotoWrapper fluid={props.image.fluid} alt={props.image.title}/>
    )
}

export default Photo;