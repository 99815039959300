import React from 'react'
import styled from 'styled-components'
import { renderBlocks } from '../utils/render-fns';
import RoundCutFooter from '../RoundCutFooter';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: ${props => props.overflowSection ? '-100px' : '1px'};

  margin-left: 3em;
  margin-right: 3em;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
      grid-template-columns: 1fr; 
      margin-left: 2em;
      margin-right: 2em;
      margin-bottom: 5em;        
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`

const SmallTwoColumn = props => {
  return (
    <Wrapper {...props}>
      { renderBlocks(props.blocks) }
      {
        !props.section.roundCutFooter ? null:
        <RoundCutFooter backgroundColor={props.section.backgroundColor}/>
      }
    </Wrapper>
  )
}

export default SmallTwoColumn
