import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import FooterEventsContainer from './FooterEvents'

const Outer = styled.div`
  background-color: ${props => props.theme.colors.footerBGColor};
  position: relative;
  color: white;
  font-family: ${props => props.theme.fonts.footerFontface};
  z-index: 3;
`

const Wrapper = styled.footer`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const BackToTop = styled.a`
  font-size: 1.5em;
  font-weight: normal;
  width: 50px;
  height: 50px;
  background: ${props => props.theme.colors.footerBGColor};
  color: white;
  right: 20px;
  position: absolute;
  bottom: 0;
  padding: 13px;
  padding-top: 4px;
`

const BottomLine = styled.div`
  font-size: 18px;
  color: ${props => props.theme.colors.footerBootomline};
  background-color: black;
  margin: 0 auto;
  text-align: center;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    height: 170px;
    align-items: flex-start;
    padding: 45px;
    padding-top: 21px;
    line-height: 23px;
  }
`

const Bottom = styled.div`
  font-size: 22px;
  color: ${props => props.theme.colors.footerEventTextColor};
  background-color: ${props => props.theme.colors.footerBGColor};
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 55px;
  display: block;
  line-height: 45px;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    align-items: flex-start;
    padding: 42px;
    padding-top: 0;
    line-height: 28px;
    height: 135px;
    display: block;
    font-size: 18px;
  }
  a {
    color: #868686;
    margin-left: 4px;
    text-decoration: none;
  }

  
  @media (max-width: ${props => props.theme.responsive.small}) {
    height: 155px;
  }

  `

const SkidBikeLogo = styled(Img)`
  width: 256px !important;
  height: 150px !important;

  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 203px !important;
    height: 120px !important;
  }

`
const SkidcarLogo = styled(Img)`
  width: 203px !important;
  height: 243px !important;
  object-fit: ${props => props.fit || 'cover'};
  object-position: ${props => props.position || '50% 50%'};
  margin-top: -80px;
  background: #242424;
  border: 8px solid #242424;

  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 153px !important;
    height: 183px !important;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    order: -1;
  }
`
const SkidcarVst = styled(Img)`
  width: 270px !important;
  height: 96px !important;
  object-fit: ${props => props.fit || 'cover'};
  object-position: ${props => props.position || '50% 50%'};

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 215px !important;
    height: 73px !important;
  }
`

const getYear = () => {
  const date = new Date()
  return date.getFullYear()
}

const RoundFooter = styled.div`
  height: 160px;
  top: -54px;
  background: #252424;
  border-radius: 100% 100% 0 0;
  position: absolute;
  right: -10%;
  left: -10%;
  content: '';

  @media (max-width: ${props => props.theme.responsive.small}) {
    border-radius: 0;
    height: 110px;
    width: 100%;
    margin-top: -54px;
    background: #252424;
    position: absolute;
    padding: 0;
    left: 0;
  }
`

const FooterLogoWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  margin-bottom: 60px;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
    display: flex;
    height: 430px;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    .skidcar-logo {
      order: -1;
    }

  }




`

const backToTopClicked = e => {
  e.preventDefault()
  // document.body.scrollTop = 0; // For Safari
  // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const MainFooterBottom = ({ data, slug }) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const { logoSkidcar, logoVst, logoSkidcarBike } = data
        return (
          <Outer>
            <RoundFooter />
            <Wrapper>
              <FooterLogoWrapper>
                <div className="skidbike">
                  <a rel={"noreferrer"} target={"_blank"} href={"https://www.skidbike.com/"}>
                    <SkidBikeLogo
                      fixed={logoSkidcarBike.childImageSharp.fixed}
                      alt="SKIDBIKE logo"
                    />
                  </a>
                </div>
                <div className="skidcar-logo">
                  <Link  to="/">
                    <SkidcarLogo
                      fixed={logoSkidcar.childImageSharp.fixed}
                      alt="SKIDCAR System logo"
                    />
                  </Link>
                </div>
                <div className="skidcar-vst">
                  <Link to="/products-virtual-skidcar-trainer">
                    <SkidcarVst
                      fixed={logoVst.childImageSharp.fixed}
                      alt="Virtual SKIDCAR Trainer logo"
                    />
                  </Link>
                </div>
              </FooterLogoWrapper>
              {slug === 'home' ? <FooterEventsContainer /> : null}
              <Bottom>
                6440 Sky Pointe Drive, Suite 140, PMB 141, Las Vegas, NV 89131 |
                <a href="tel:1-866-754-3227">866.754.3227</a> / <a href="tel:702-395-2896">702.395.2896</a> |
                <a href="mailto:info@skidcar.com">info@skidcar.com</a>
              </Bottom>
            </Wrapper>
            <BottomLine>
              &copy; Copyright {getYear()} | SKIDCAR, SKIDTRUCK, SKIDCAR SYSTEM,
              AND "IT'S ABOUT GRIP, NOT SLIP" are the trademarks of SKIDCAR
              SYSTEM, INC.
            </BottomLine>
            <BackToTop onClick={backToTopClicked} href="">
              <FaChevronUp />
            </BackToTop>
          </Outer>
        )
      }}
    />
  )
}

export const query = graphql`
  query {
    logoSkidcarBike: file(relativePath: { eq: "skidbike-logo@2x.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoSkidcar: file(relativePath: { eq: "skidcar-system-logo@2x.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logoVst: file(relativePath: { eq: "skidcar-virtual-trainer@2x.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default MainFooterBottom
