import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
    font-size: ${props => props.fontsize};
    font-weight: ${props => props.fontweight};
    line-height: ${props => props.fontsize};
    float: left;

    @media (max-width: ${props => props.theme.responsive.small}) {
        font-size: ${props => props.mfontsize || "inherit"};
        font-weight: ${props => props.mfontweight || "inherit"};
        line-height: ${props => props.mlineheight || "inherit"} ;
        float: none;
    }
`

const BorderText = props => {
    return ( 
        <Text {...props}>{props.children}</Text>
    )
};

export default BorderText