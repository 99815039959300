import React from 'react'
import styled from 'styled-components'
import RoundCutFooter from './RoundCutFooter'

const Wrapper = styled.div`
  text-align: center;
  padding: 2em 1em;

  h2 {
    font-family: ${props => props.theme.fonts.lightFont.family};
    font-weight: ${props => props.theme.fonts.lightFont.weight}; 
    font-size: 1.875em;
    margin-bottom: 1em;
    line-height: 130%;
  }
  
  p {
    margin: 0 auto;
    max-width: ${props => props.theme.sizes.maxWidthCentered};
  }
`

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fonts.sectionTitleFont.size};
  padding: 40px 0 38px 0;
  font-weight: 800;
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  width: 90%;
  margin: auto;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 0 38px 0;
    font-size: 2.375rem;
    font-weight: 800;
    width: 100%;
  }

`

const PrimaryColorLine = styled.div`
  height: 6px;
  width: 290px;
  background-color: ${props => props.theme.colors.primary};
  margin:auto;
`

const Body = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 5px;  
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding: 0;  
  }
`

const ExclusiveBoxes = props => {
  const {slug, section, subtitle, children} = props;
  const desc = !section.body ? null : section.body.childMarkdownRemark.rawMarkdownBody;
  const sectionHeader = (section.displayTitle || section.title) &&(
    <>
      <SectionTitle  dangerouslySetInnerHTML={{__html:section.displayTitle || section.title}}/>
      <PrimaryColorLine />
      {section.displaySubTitle || section.subTitle ? <h3>{section.displaySubTitle || section.subTitle}</h3> : ''}
    </>
  )

  return (
    <><Wrapper id={slug}>
      {sectionHeader}
      {subtitle ? <p>{subtitle}</p> : null }
      {!desc ? null : <Body dangerouslySetInnerHTML={{ __html: desc}}  >
      </Body>}
      <BoxWrapper>
        {children}
      </BoxWrapper>

    </Wrapper>
    {section && section.roundCutFooter ? <RoundCutFooter backgroundColor={section && section.backgroundColor}/> : null}
  </>);
}

export default ExclusiveBoxes
