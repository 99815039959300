import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components' // eslint-disable-line import/no-duplicates
import Header from '../../components/layout/header/Header'
import GlobalStyle from '../../styles/global'
import theme from '../../styles/theme'
import Container from '../Container'
import Hero from '../Hero'
import SEO from '../seo/SEO'
import { renderAst, renderSections } from '../utils/render-fns'
import HeroHome from './../HeroHome.js'
import MainFooterBottom from './footer/MainFooterBottom'

const MainContent = styled.div`
  flex-direction: column;
  padding-right: ${props => (props.layout === 'Right Column' ? '1.5em' : '0')};
  flex: 1;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding-right: 0;
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    border-right: ${props =>
      props.layout === 'Right Column'
        ? '1px solid ' + props.theme.colors.black
        : 'none'};
  }

  .contentBody {
    margin-bottom: 2em;
  }
`

const renderDefaultHero = () => (
  <StaticQuery
    query={query}
    render={data => {
      return data && <Hero img={data.heroImage.childImageSharp} title={'Page not found'} />
    }}
  />
)

const PageLayout = ({ data, children }) => {
  return (
    <div className="siteRoot">
      <SEO data={data} />

      <ThemeProvider theme={theme}>
        <div className="siteContent">

          <Container>
            <div>
              {/**************************************************
               * Main Content
               * Drop all main content body elements
               * in this section
               *
               * Check Page Type
               * use pageType to indicate which template to load
               *************************************************/}
              <MainContent
                className="mainContent"
                layout={data && data.pageLayout}
                >
                  <Header data={data} />

                  {data && data.heroSlideshow ? (
                    <HeroHome
                      homeBoxes={data.homeBoxOverlays}
                      slides={data.heroSlideshow}
                    />
                  ) : data && data.heroImages && data.heroImages.length ? (
                    <Hero
                      img={data.heroImages[0]}
                      images={data.heroImages}
                      title={data.displayTitle}
                    />
                  ) : (
                    renderDefaultHero()
                  )}
                  {children}
                  {data && data.body && <div className="contentBody"><div>{renderAst(data.body.childMarkdownRemark.htmlAst)}</div></div>}
                  {data && data.pageSections && renderSections(data.pageSections, true)}
                  <MainFooterBottom slug={data && data.slug} data={data} />
              </MainContent>
            </div>
          </Container>
          {/* <Popup /> */}
        </div>
        <GlobalStyle />
      </ThemeProvider>
    </div>
  )
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "heroImage.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default PageLayout
