import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */
  html, body {
    height: 100%;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    flex: 1 0 auto;
    overflow-x: hidden; /* hide extra hoz round footer component */
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      // margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul {
    list-style: none;
    font-size: 1em;
  }


  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  :root {
    --base-font-size: ${props => props.theme.fonts.fontSizes.base0};
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    :root {
      --base-font-size: ${props => props.theme.fonts.fontSizes.base0};
    }
  }

  html {
    font-size: var(--base-font-size);
  }

  svg {
    vertical-align: middle;
  }

  body {
    background: white;
    line-height: 1;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: ${props => props.theme.fonts.bodyFont.weight};
    font-family: ${props => props.theme.fonts.bodyFont.family};
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
  }

  img {
    display: block;
  	max-width: 100%;
  }

  h1 {
    font-family: ${props => props.theme.fonts.h1Font.family};
    font-weight: ${props => props.theme.fonts.h1Font.weight};
    color: ${props => props.theme.fonts.h1Font.color};
    font-size: ${props => props.theme.fonts.h1Font.size};
    text-transform: uppercase;
  }

  h2 {
    font-family: ${props => props.theme.fonts.h2Font.family};
    font-weight: ${props => props.theme.fonts.h2Font.weight};
    color: ${props => props.theme.fonts.h2Font.color};
    font-size: ${props => props.theme.fonts.h2Font.size};
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  h3 {
    font-family: ${props => props.theme.fonts.h3Font.family};
    font-weight: ${props => props.theme.fonts.h3Font.weight};
    color: ${props => props.theme.fonts.h3Font.color};
    font-size: ${props => props.theme.fonts.h3Font.size};
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  h4 {
    font-family: ${props => props.theme.fonts.h4Font.family};
    font-weight: ${props => props.theme.fonts.h4Font.weight};
    font-size: ${props => props.theme.fonts.h4Font.size};
    color: ${props => props.theme.fonts.h4Font.color};
    margin-bottom: 1em;
    text-transform: capitalize;
  }

  p {
    font-size: ${props => props.theme.fonts.pFont.size};
    line-height: ${props => props.theme.fonts.pFont.lineHeight};
    margin-bottom: ${props => props.theme.fonts.pFont.marginBottom};
  }

  em, i {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  .mainContent ul {
    margin: 1.5em 0;
  }

  blockquote {
    font-size: 1.125em;
    font-style: italic;
    margin: 1em 0 1em 3em;
    padding: .4em 0 .4em 1.5em;
    line-height: 150%;
    border-left: 4px solid ${props => props.theme.colors.base};
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    .mainContent .floatImage {
      float: none !important;
      width: 80%;
      margin: 0 auto;
    }
  }

  /* popupbox css moved here had issues loading from vendor */
  .popupbox{
    width:100%;
    height:100%;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
  };

  .popupbox[hidden]{
    display: none;
  }

  .popupbox-overlay{
    position:fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    z-index:1002;
    background: none repeat scroll 0% 0% #000;
  }

  .popupbox-content {
    overflow: hidden;
  }

  .popupbox-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    z-index: 1003;
    position: relative;
  }

  .popupbox-titleBar {
    overflow: hidden;
    display: block;
    position: relative;
  }

  .popupbox-btn--close {
    z-index: 1004;
  }

  .popupbox[data-title='bottom'] .popupbox-content { order: 1 }
  .popupbox[data-title='bottom'] .popupbox-titleBar { order: 2 }

  /* ----- default theme ----- */

  .popupbox-wrapper {
    border-radius: 3px;
    overflow: hidden;
    max-width: 80%;
    min-width: 300px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .9);
    background-color: white;
  }

  .popupbox-content {
    padding: 20px 24px 30px;
  }

  .popupbox-titleBar {
    font-weight: bold;
    font-size: 18px;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, .2);
    padding: 11px 37px 11px 24px;
    border-bottom: 1px #ccc solid;
  }

  .popupbox-btn--close {
    transition: all .5s;
    position: absolute;
    right: 11px;
    top: 11px;
    color: #c1c1c1;
    background: none;
    border: none;
    outline: none;
  }

  .popupbox-btn--close:hover {
    color: #000;
  }

  .popupbox.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  .popupbox[data-title='bottom'] .popupbox-content { box-shadow: 0 1px 1px rgba(0, 0, 0, .3) }
  .popupbox[data-title='bottom'] .popupbox-titleBar { box-shadow: none; border-top: 1px #ccc solid }

  /* overrides */
  .popupbox-wrapper {
    border-radius: 20px !important;
    height: 350px; /* fixed height for ask experts iframe load */

    .popupbox-content {
      overflow: auto;
      padding: .5em;
    }
  }

  .email-link {
    color: #171717;
  }

  a[href^="https://"], [href^="http://"], [href^="/"], [href^=".."], [href^="mailto:"]  {
    color: currentColor;
    text-decoration: underline;
  }

  .align-right {
    text-align:right;
    align-items:flex-end;
  }

  .styled-block {
    h3 {
      color: #ffffff;
      font-weight: 600;
      margin-bottom: .5em;
    }

    p {
      line-height: 125%;
      margin-bottom: 1.5em;
    }

    a {
      color:#ffffff
    }
  }

  .click-to-download {
    .gatsby-resp-image-wrapper {
      max-width: none!important;
      padding: 1em;
      @media only screen and (max-width: 600px) {
        padding: 0;
      }
    }
  }

  .active-link {
    color: #00a1e0!important;
  }

  .gatsby-resp-image-wrapper {
    .gatsby-resp-image-image{
      box-shadow: none!important;
    }
  }

  .align-left {
    text-align:left;
    align-items:flex-start;
  }

  .num-color {
    font-size:24px;
    line-height: 35px;
    color: #ffffff;
    text-indent: -39px!important;

    div > span {
      font-size: 26px;
      color: #00a1e0;
      font-family: 'Oswald', sans-serif;
      font-weight: 600;
      padding: 9px;
      @media (max-width: 35em) {
        font-size: 18px;
      }
    }
  }

  .padding-top-50 {
    padding-top: 50px;
  }

  .visibility-none {
    visibility: hidden;
  }

  .is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  .invisible-block{
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-out, transform 300ms ease-out;
    will-change: opacity, visibility;
  }

  .margin-bottom-100 {
    margin-bottom: 100px;
  }

  .jotform-form {
    .form-line {
      padding-right: 46px!important;
    }
  }

  .full-width {
    width: 100%;
  }

  // Animation

  .fade-in {
    animation: fadeIn ease 0.5s;
    }
    @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

`;
