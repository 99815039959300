import Img from 'gatsby-image'
import { get } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import LargeArrowLeft from '../images/large-arrow-left.png'
import LargeArrowRight from '../images/large-arrow-right.png'
import Theme from '../styles/theme.js'
import HeroSlider from './slider/HeroSlider'
import { renderAst } from './utils/render-fns'

const Wrapper = styled.section`
  position: relative;
  height: 700px;
  border-bottom: 20px solid ${props => props.theme.colors.primary};

  @media (max-width: ${props => props.theme.responsive.small}) {
    height: auto;    
  }
  
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
`

const ArrowButton = styled.span`
  display: block;
  background-image: url(${props => props.image});
  width: 38px;
  height: 109px;
  background-repeat: no-repeat;
`

const PrimaryBorder = styled.div`
  border: 8px solid ${props => props.theme.colors.primary};
  height: 60%; 
`

const BorderTextBackground = styled.h1`
  color: white;
`

const HeroImageMobile = styled.div`
  display: none;

  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    display: block;
    .gatsby-image-wrapper {
      img {
        object-fit: contain;
        object-position: top center;
      }
    }
  }    
  @media (max-width: ${props => props.theme.responsive.medium}) {
    .hr-blue-line {
      background: #03a0df;
      height: 7px;
      width: 97%;
      border: 0;
      margin-top: 3px;
    }  
  }
`

const HeroHome = props => {
  const { slides } = props;
  var firstSlide = get(slides, '0.mobileImage.fluid', null);
  var firstSlideTitle = get(slides, '0.mobileImage.title', null);
  var firstText = get(slides, '0.borderText.childMarkdownRemark.htmlAst', { type: 'root', children: [] });
  return (
    <div>
      <Wrapper>
        {firstSlide ?
          <HeroImageMobile>
          <Img fluid={firstSlide} style={{position: 'block', height: '100%'}} title={firstSlideTitle} alt={firstSlideTitle} />
          <BorderTextBackground>
            {renderAst(firstText)}
          </BorderTextBackground>

        </HeroImageMobile> : null}
        <HeroSlider settings={Theme.slideshows.heroSlideshow} previousButton={<ArrowButton image={LargeArrowLeft} />} nextButton={<ArrowButton image={LargeArrowRight} />} infinite touchDisabled className="slider" autoplay={Theme.slideshows.heroSlideshow.speed}>
          {slides.map((slide, index) => {
            var borderTextRaw = get(slide, 'borderText.childMarkdownRemark.htmlAst', { type: 'root', children: [] });
            const pageUrl = slide?.pageLink?.slug;
            return (
              <div key={`slide${index}`} className="sliderContent">
                <a href={`/${pageUrl}`} key={`slide${index}`} >
                <Img style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%"
                }} fluid={slide.image.fluid}  alt={slide.image.title} title={slide.image.title}/>
                <div className="inner">
                  <PrimaryBorder className="title">
                    <BorderTextBackground>
                      {renderAst(borderTextRaw)}
                    </BorderTextBackground>
                  </PrimaryBorder>
                </div>
              </a>
              </div>
            )
          })}
        </HeroSlider>
      </Wrapper>
    </div>
  )
}

export default HeroHome
