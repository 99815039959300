import React from 'react'
import styled from 'styled-components'
import { renderBlocks } from '../utils/render-fns';
import RoundCutFooter from '../RoundCutFooter';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 38px 45px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1em;
  padding-top: ${props => props.paddingTop ? '100px' : ''};
  
  @media (max-width: ${props => props.theme.responsive.small}) {
      grid-template-columns: 1fr;
  }
`

const ThreeColumn = props => {
  return (
    <>
    <Wrapper>
      { renderBlocks(props.blocks) }
    </Wrapper>
    {props.section && props.section.roundCutFooter ? <RoundCutFooter backgroundColor={props.section && props.section.backgroundColor}/> : null}
    </>
  )
}

export default ThreeColumn
