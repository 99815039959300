import React from 'react'
import styled from 'styled-components'

const RoundCutWrapper = styled.div`
    display: block;
    width: 100%;
    padding-bottom: 16%;
    /* bottom: 0; */
    left: 0;
    overflow: hidden;
    position: absolute;
    background-color: transparent;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    z-index: 0;

    .circle {
      display: block;
      width: 140%;
      padding-bottom: 112%;
      bottom: 0;
      overflow: hidden;
      position: absolute;
      background-color: transparent;
      z-index: 99999999;
      
      @media (max-width: ${props => props.theme.responsive.small}) {
        padding-bottom: 220%;
      }
    }

  .circle:after{
    box-sizing: content-box;
    content: '';
    width: 200%;
    height: 100%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0,0,0,0);
    position: absolute;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    top: 48%;
    left: 36%;
    border: 132px solid ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
    z-index: 999;

    @media (max-width: ${props => props.theme.responsive.medium}) {
      top: 39%;
      // top: 239px;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      top: 46%;
    }
  }

`



  const RoundCutFooter = ({backgroundColor, slug}) => {
  return (
      <RoundCutWrapper backgroundColor={backgroundColor}><span className="circle"></span></RoundCutWrapper>
    )
  }

export default RoundCutFooter
