import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Title = styled.span`
  color:white;
  font-family: ${props => props.theme.fonts.h3Font.family};
  font-weight: 600;
  font-size: 65px;
  text-transform: uppercase;
  transition: 0.2s;
  margin: 0 auto;
  line-height: 120%;
  display: block;
  max-width: 500px;
  filter: drop-shadow(0 0 3.5px rgba(0,0,0,0.63));
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 55px;
    max-width: 83%;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 45px;
    max-width: 80%;
  }
  
`
const SubTitle = styled.span`
  color:white;
  font-family: ${props => props.theme.fonts.topMenuFont.family};
  font-weight: 200;
  font-size: 24px;
  transition: 0.2s;
  margin: 0 auto;
  margin-top: 1em;
  line-height: 125%;
  display: block;
  text-shadow: 0 3px 46px rgba(0, 0, 0, 1);
  max-width: 95%;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 18px;
    max-width: 75%;
  } 
`

const SubHeader = styled.div`
  font-family: ${props => props.theme.fonts.topMenuFont.family};
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 0.2em;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 5px;  
  transition: 0.2s;
  margin: 0 auto;
  margin-top: 1em;
  line-height: 125%;
  display: block;
  text-shadow: 0 3px 46px rgba(0, 0, 0, 1);

  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 18px;
  } 
`;

const PhotoBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;  
  max-width: 555px;
  width:100%;
  height: 456px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    max-height: 546px;
    margin: auto;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${props => props.theme.responsive.small}) {
    // margin-bottom: 20px;
    max-width: none;
  }
    
  &:hover::before {
    transform: scale(1.1);
  }
  
  .inner {
    transition: background-color 300ms linear; 
    background-color: rgba(39, 39, 39, 0.35);    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  
  &:hover {
    .inner {
      background-color: rgba(39, 39, 39, 0.65);
    }
  }
  
  &::before {
  backface-visibility: hidden; /* prevent jump effect when scaling */
    content: '';    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    transition: 1s;
    background-image: url(${props => props.bgimg});
    background-size: cover;
    background-repeat: no-repeat;    
  }
`

const Content = styled.span`
  position: absolute;
  top:15px;
  left:50%;
  transform: translate(-50%);
  bottom: 45px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    bottom: initial;
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    top:25px;
    bottom: 25px;
  }

  > .flex {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 358px;
  }
`

const Button = styled.button`
  padding: 20px 36px 20px 36px; 
  border: 1px solid white;
  border-radius: 2px;
  background-color: transparent;
  font-family: ${props => props.theme.fonts.buttonFont.family};
  font-weight: ${props => props.theme.fonts.buttonFont.weight};
  letter-spacing: 5px;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  margin-top: 2em;
  transition: all 0.3s ;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.colors.primary}
  }
`

const PhotoBoxButton = (props) => {
    const {image, displayTitle, subTitle, linkText, slug, subHeader, linkPath} = props;
    let link = linkPath;

  return (
    <PhotoBox id={slug} bgimg={image ? image.fluid.src : {}}>
      <span className="inner">
        <Content>
          <div className="flex">
            <div>
              {subHeader? <SubHeader dangerouslySetInnerHTML={{ __html: subHeader }}/>:null}
              {displayTitle? <Title>{displayTitle || 'Title'}</Title>:null}
              {subTitle? <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }}/>:null}
            </div>
            <div>
            {
              link ? <Link to={link}><Button>{linkText || 'Link Text'}</Button></Link> : null
            }
            </div>
          </div>
        </Content>
      </span>
    </PhotoBox>
  )
}

export default PhotoBoxButton
