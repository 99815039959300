import React, { Component } from 'react'
import Helmet from 'react-helmet'
import favicon114114 from '../../new-images/favicons/apple-touch-icon-114x114.png'
import favicon120120 from '../../new-images/favicons/apple-touch-icon-120x120.png'
import favicon144144 from '../../new-images/favicons/apple-touch-icon-144x144.png'
import favicon152152 from '../../new-images/favicons/apple-touch-icon-152x152.png'
import favicon5757 from '../../new-images/favicons/apple-touch-icon-57x57.png'
import favicon6060 from '../../new-images/favicons/apple-touch-icon-60x60.png'
import favicon7272 from '../../new-images/favicons/apple-touch-icon-72x72.png'
import favicon7676 from '../../new-images/favicons/apple-touch-icon-76x76.png'
import faviconAppleTouch from '../../new-images/favicons/apple-touch-icon.png'
import favicon128 from '../../new-images/favicons/favicon-128.png'
import favicon1616 from '../../new-images/favicons/favicon-16x16.png'
import favicon196196 from '../../new-images/favicons/favicon-196x196.png'
import favicon3232 from '../../new-images/favicons/favicon-32x32.png'
import favicon9696 from '../../new-images/favicons/favicon-96x96.png'
import faviconms144144 from '../../new-images/favicons/mstile-144x144.png'
import faviconms150150 from '../../new-images/favicons/mstile-150x150.png'
import faviconms310150 from '../../new-images/favicons/mstile-310x150.png'
import faviconms310310 from '../../new-images/favicons/mstile-310x310.png'
import faviconms7070 from '../../new-images/favicons/mstile-70x70.png'
import config from '../../utils/siteConfig'

class SEO extends Component {
    render() {
        const { data } = this.props
        if(!data) return null

        const { seo } = data

        // Instantiate the variable
        let title, description, ogTitle, ogDescription, ogImage, ogImageAlt, ogURL

        /**
         * If an SEO object is availble then
         * 1) Use the seo specific value
         * 2) Fall back to the page value
         * 3) Fall back to the site config value
         */
        if (seo) {
            title = seo.title ? seo.metaTitle : data.displayTitle ? data.displayTitle : config.siteTitle
            description = seo.description ? seo.metaDescription : data.displaySummary ? data.displaySummary : config.siteDescription
            ogTitle = seo.ogTitle ? seo.ogTitle : data.displayTitle ? data.displayTitle : config.siteTitle
            ogDescription = seo.ogDescription ? seo.ogDescription : data.displaySummary ? data.displaySummary : config.siteDescription
            ogImage = seo.ogImage ? seo.ogImage.fluid.src : `//www.skidcar.com${config.shareImage}`
            ogImageAlt = seo.ogImage ? seo.ogImage.title : data.displayTitle ? data.displayTitle : config.siteTitle
            ogURL = seo.openGraphUrl ? seo.openGraphUrl : config.siteUrl
        } else {
            title = data.displayTitle ? data.displayTitle : config.siteTitle
            description = data.displaySummary ? data.displaySummary : config.siteDescription
            ogTitle = data.displayTitle ? data.displayTitle : config.siteTitle
            ogDescription = data.displaySummary ? data.displaySummary : config.siteDescription
            ogImage = `//www.skidcar.com${config.shareImage}`
            ogImageAlt = config.siteTitle
            ogURL = config.siteUrl
        }

        // console.log('************** Location ****************')
        // console.log(location)
        // console.log('****************************************')
        // Default Website Schema
        const schemaOrgJSONLD = [
            {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: config.siteUrl,
            name: config.siteTitle,
            alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
            },
        ]
        return (
            <Helmet title={title}>
                {/* General tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={description}/>

                {/* Schema.org Tags */}
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgJSONLD)}
                </script>

                {/* OpenGraph tags */}
                <meta property="og:title" content={ogTitle.trim()} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:url" content={ogURL} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={`https:${ogImage}`} />
                <meta property="og:image:alt" content={ogImageAlt} />

                {/* Twitter Cards */}
                <meta name="twitter:card" content="Summary" />
                <meta name="twitter:title" content={ogTitle.trim()} />
                <meta name="twitter:description" content={ogDescription} />
                <meta name="twitter:image" content={`https:${ogImage}`} />

                <link rel="apple-touch-icon-precomposed" sizes="57x57" href={favicon5757} />
                <link rel="apple-touch-icon-precomposed" sizes="114x114" href={favicon114114} />
                <link rel="apple-touch-icon-precomposed" sizes="72x72" href={favicon7272} />
                <link rel="apple-touch-icon-precomposed" sizes="144x144" href={favicon144144} />
                <link rel="apple-touch-icon-precomposed" sizes="60x60" href={favicon6060} />
                <link rel="apple-touch-icon-precomposed" sizes="120x120" href={favicon120120} />
                <link rel="apple-touch-icon-precomposed" sizes="76x76" href={favicon7676} />
                <link rel="apple-touch-icon-precomposed" sizes="152x152" href={favicon152152} />
                <link rel="apple-touch-icon-precomposed"  href={faviconAppleTouch} />
                <link rel="icon" type="image/png" href={favicon196196} sizes="196x196" />
                <link rel="icon" type="image/png" href={favicon9696} sizes="96x96" />
                <link rel="icon" type="image/png" href={favicon3232} sizes="32x32" />
                <link rel="icon" type="image/png" href={favicon1616} sizes="16x16" />
                <link rel="icon" type="image/png" href={favicon128} sizes="128x128" />
                <meta name="application-name" content="&nbsp;"/>
                <meta name="msapplication-TileColor" content="#FFFFFF" />
                <meta name="msapplication-TileImage" content={faviconms144144} />
                <meta name="msapplication-square70x70logo" content={faviconms7070} />
                <meta name="msapplication-square150x150logo" content={faviconms150150} />
                <meta name="msapplication-wide310x150logo" content={faviconms310150} />
                <meta name="msapplication-square310x310logo" content={faviconms310310} />

            </Helmet>
        )
    }
}

export default SEO
