import React from 'react';
import styled from 'styled-components';
import { renderAst } from './utils/render-fns';

const Title = styled.span`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 600;  
  transition: 0.2s;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  display: block;
  font-size: 22px;
  line-height: 130%;
`

const PartNo = styled.div`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 400;  
  transition: 0.2s;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  padding: 15px 0;
`

const TopImageTall = styled.img`
  width: 100%;
  height: 215px;
  display: block;
  min-width: 250px;
  //background-position: top center;
  //background-repeat: no-repeat;
  //background-size: cover;
  //transition: 1s;
  //display: block;
  //backface-visibility: hidden; /* prevent jump effect when scaling */
`

const Description = styled.div`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 300;
  p {
    font-size:20px;
    color: ${props => props.theme.colors.base}
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size:18px;
    }

  }
`
const Content = styled.div`
  padding: 1.2em .6em;
  margin-bottom: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;

  &.iconAlignment {
    display: block;
  }
`

const IconLine = styled.span`
  display: block;
  height: 12px;
  background-color: ${props => props.theme.colors.base};
  position: absolute;
  width: 100%;
  top: 160px;
  z-index: 1;
  
  &::before {
    content: '';
    display: block;
    background-size: contain;
    background-image: url(${props => props.image.fixed.src});
    background-repeat: no-repeat;
    margin: -30px auto 0;
    width:66px;
    height:66px;
  }
`

const IconBox = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;  
  overflow: hidden;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  min-width: 174px;  
  max-width: 372px;
  border: 1px solid #bbbbbb;
  padding: .5em;
  background: #f0f0f0;
  border-radius: 4px;
  //margin: 25px auto;
  height: 100%;

  @media (max-width: ${props => props.theme.responsive.large}) {  
    margin-bottom: 2em;
    
    // last 2 items in bottom row remove bottom margins (assumes always 2 cols )
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
    
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    max-width: none;
    height: auto;
    // width: 100%;
    // last 2 items in bottom add back bottom margins (assumes always 2 cols )
    &:nth-last-child(-n+2) {
      margin-bottom: 2em;
    }
    
    // last item remove bottom margin to override above
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const IconWithTextContainer = styled.span`
  border-top: 2px solid #b5b5b5;
  padding-top: 5px;
  text-align: left;
  margin: 0.2em .6em;

  svg {
      background: ${props => props.theme.colors.primary};
      border-radius: 50px;
      font-size: 18px;
      fill: #ffffff;
      vertical-align: baseline;
  }
 
  span {
    font-size: 20px;
    line-height: 32px;
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.titleFontface};
    font-weight: 600;
    padding-left: 8px;
  }
`

const TickSvg = () => (
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.972 6.251c-.967-.538-2.185-.188-2.72.777l-3.713 6.682-2.125-2.125c-.781-.781-2.047-.781-2.828 0-.781.781-.781 2.047 0 2.828l4 4c.378.379.888.587 1.414.587l.277-.02c.621-.087 1.166-.46 1.471-1.009l5-9c.537-.966.189-2.183-.776-2.72z"></path></svg>
)

const PartsCard = props => {
  let bgimg = null;
  if (props.image) {
    bgimg = props.image;
  }
  if (bgimg && typeof bgimg === 'object' && bgimg.fluid) {
    bgimg = bgimg.fluid.src;
  }

  const title = !props.displayTitle  ? null : props.displayTitle;
  const subTitle = !props.subTitle ? null : props.subTitle;
  const body = props.body;
  return (
    <IconBox id={props.slug}>
      <div>
        <TopImageTall src={bgimg} />
        {props.icon ? <IconLine image={props.icon} /> : ''}
      </div>
      <Content className="iconAlignment">
        <div>
          <Title>{title}</Title>
          <PartNo>{subTitle}</PartNo>
        </div>

        {!props.body ? null :
          <Description>{renderAst(body.childMarkdownRemark.htmlAst)}</Description>
        }

      </Content>

      <IconWithTextContainer>
        <TickSvg/>
        <span>Call/Email for Price Quote</span>
      </IconWithTextContainer>
    </IconBox>
  )
}

export default PartsCard;
