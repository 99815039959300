import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';


const SectionBlock = styled.div`
  text-align: left;
`

const SectionTitle = styled.div`
  font-size: 45px;
  color: #404040;
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  font-weight: 600;
  padding: 80px 0 41px 0;
  margin:auto;
  
  sup {
    font-weight: 600;
    vertical-align: 17px;
    font-size: 25px;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 600;
    width: 100%;
    line-height: 50px;
    padding-top: 40px;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
`

const SectionSubTitle = styled.div`
  font-size: ${props => props.theme.fonts.sectionSubTitleFont.size};
  padding: 0 0 100px 0;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  margin:auto;
  color: ${props => props.theme.colors.primary};

  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 800;
    width: 100%;
  }
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  font-family: ${props => props.theme.fonts.sectionDescriptionFont.family};
  font-size: ${props => props.theme.fonts.sectionDescriptionFont.size}; 
  margin: auto;
  word-spacing: 4px;
  line-height: 1.4;
  padding-bottom: 0 30px 30px;

  @media (max-width: ${props => props.theme.responsive.small}) {  
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
`


const LogoWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 30px;
  align-items: center;


    @media (max-width: ${props => props.theme.responsive.small}) {
      // flex-direction: column;
      width: 90%;
      // justify-content: space-between;
      align-items: center;
    }

    .description-container {    
      font-size: 22px;
      line-height: 31px;
      color: #171717;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      text-align: left;
      margin-left: 30px;
      
        @media (max-width: ${props => props.theme.responsive.medium}) {
          margin-left: 15px;
          * {
            font-size: 15px;
          }
        }
    }
`

const Logo = styled(Img)`
  min-width: 81px!important;
  min-height: 81px!important;
  width: 100%;
  object-fit: scale-down;
  object-position: bottom;
  margin: auto;
  border-radius: 50%;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    min-width: 68px!important;
    min-height: 60px!important;
  }
`

const Name = styled.div`
  font-size: 22px;
  line-height: 28px;
  color: #171717;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
`

const Title = styled.div`
  font-size: 22px;
  line-height: 28px;
  color: #171717;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
`;

const Email = styled.a`
  font-size: 22px;
  line-height: 28px;
  color: #171717;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
`;


const SkidcarTeam = props => {
  const headerTitle = !props.headerTitle ? null : props.headerTitle;
  const photo1 = !props.photo1 ? null : props.photo1;
  const photo2 = !props.photo2 ? null : props.photo2;
  const photo3 = !props.photo3 ? null : props.photo3;
  const photo4 = !props.photo4 ? null : props.photo4;
  const name1 = !props.name1 ? null : props.name1;
  const name2 = !props.name2 ? null : props.name2;
  const name3 = !props.name3 ? null : props.name3;
  const name4 = !props.name4 ? null : props.name4;
  const title1 = !props.title1 ? null : props.title1;
  const title2 = !props.title2 ? null : props.title2;
  const title3 = !props.title3 ? null : props.title3;
  const title4 = !props.title4 ? null : props.title4;
  const email1 = !props.email1 ? null : props.email1;
  const email2 = !props.email2 ? null : props.email2;
  const email3 = !props.email3 ? null : props.email3;
  const email4 = !props.email4 ? null : props.email4;



  return (props.headerTitle ?
    <SectionBlock >
      <SectionTitle  dangerouslySetInnerHTML={{ __html: props.headerTitle}} />
      {
        (photo1) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!photo1 ? null : <Logo
              fluid={photo1.fluid}
              alt={photo1.title}
            />
            }
          </div>
          <div className="description-container">
            <Name >{name1}</Name>
            <Title >{title1}</Title>
            <Email href={`mailto:${email1}`}>{email1}</Email>
          </div>
        </LogoWrapper>
        : null
      }

      {
        (photo2) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!photo2 ? null : <Logo
              fluid={photo2.fluid}
              alt={photo2.title}
            />
            }
          </div>
          <div className="description-container">
            <Name >{name2}</Name>
            <Title >{title2}</Title>
            <Email href={`mailto:${email2}`}>{email2}</Email>
          </div>
        </LogoWrapper>
        : null
      }

      {
        (photo3) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!photo3 ? null : <Logo
              fluid={photo3.fluid}
              alt={photo3.title}
            />
            }
          </div>
          <div className="description-container">
            <Name >{name3}</Name>
            <Title >{title3}</Title>
            <Email href={`mailto:${email3}`}>{email3}</Email>
          </div>
        </LogoWrapper>
        : null
      }


      {
        (photo4) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!photo4 ? null : <Logo
              fluid={photo4.fluid}
              alt={photo4.title}
            />
            }
          </div>
          <div className="description-container">
            <Name >{name4}</Name>
            <Title >{title4}</Title>
            <Email href={`mailto:${email4}`}>{email4}</Email>
          </div>
        </LogoWrapper>
        : null
      }


   </SectionBlock>
    : null)
}


export default SkidcarTeam
