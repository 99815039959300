import styled from 'styled-components'
import Slider from './Slider'
import Theme from '../../styles/theme.js'

const HeroSlider = styled(Slider)`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media (max-width: ${props => props.theme.responsive.medium}) {
      display: none;
    }

    .slideDotsContainer {
      position: absolute;
      z-index: 2;
      bottom: 0%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 40px;
    }

    & a {
      &.slideDot {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #717171;
        transition: all ${props => props.settings.prevNextButtonsScale} linear;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer
      }

      &.primary {
        background-color: ${props => props.theme.colors.primary}
      }

      $.grey {
        background-color: ${props => props.theme.colors.grey}
      }

      &.previousButton, &.nextButton {
           display: block;
           position: absolute;
           top: 50%;
           transform: translateY(-50%);
           transition: all ${props => props.settings.prevNextButtonsScale} linear;
           z-index: 2;
           padding: 10px;
           text-decoration: none;
           backface-visibility: hidden; /* prevent jump effect when scaling */
    
      &:not(.disabled):hover {
           transform: translateY(-50%) scale(1.25);
           cursor: pointer;
       }
    }
  
    &.previousButton {
      @media (max-width: ${Theme.responsive.small}) {
        display: none;
      }
         left: 8%;
     }
  
    &.nextButton {
      right: 8%;
  
      @media (max-width: ${Theme.responsive.small}) {
        display: none;
      }
     }
    }
    
  .sliderContent {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    text-align: center;
    background-size: cover !important;
    //opacity: 0;
    //&:first-of-type {
    //  opacity: 1;
    //}
  
  
    &.hidden {
       visibility: hidden;
    }
  
    &.previous {
        z-index:0;
     }
  
    &.current {
      z-index:1;
    }
  
    &.next {
      opacity: 0;
    }
  
    &.animateIn,
    &.animateOut {
         transition: all ${props => props.settings.animateTransition} ease;
    }
  
    &.animateIn {
  
      &.next {
        opacity: 1;
        z-index: 1;
        visibility: visible;
  
  
        & p {
            transition-delay: 1.1s;
        }
  
        & .title {
            transition-delay: ${props => props.settings.titleDelay};
        }
  
        & .button {
          transform: translate(-50%, -50%);
          transition-delay: ${props => props.settings.buttonDelay};
        }
  
        & section img {
          transition-delay: 1.3s;
        }
  
        & section span {
          transition-delay: 1.4s;
        }
  
        & section span strong {
          transition-delay: 1.5s;
        }
      }
    }
  
    &.animateOut {
  
      &.previous {
         opacity: 1;
       }
  
      &.next {
        opacity: 0;
      }
  
      & .title {
        transition-delay: .1s;
      }
  
      & p {
        transition-delay: .2s;
      }
  
      & .button {
        transition-delay: 1.6s;
      }
  
      & section span {
        transition-delay: .1s;
      }
  
      & section span strong {
        transition-delay: 0s;
      }
    }
  
    &.current,
    &.animateIn {
      & .title,
      & p,
      & section * {
          //transform: translateX(0);
          transition-delay: .9s;
          opacity: 1;
      }
  
      & .button {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
    
    & .inner {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
    }
  
    & .title {
      transition: all ${props => props.settings.titleTransition} ease;
      opacity: 0;
      font-size: 2.75em;
      position: absolute;
      z-index: 1;
      width: 100%;
      padding: 0 1em;
      top: 55%;
      max-width: 700px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
      text-transform: uppercase;
      line-height: 3.25rem;
      text-shadow: 0 3px 46px rgba(0, 0, 0, 1);
    }
  
    & p {
      color: #FFFFFF;
      font-size: 14px;
      line-height: 1.5;
      margin: 20px auto 30px;
      max-width: 640px;
      transition: all .3s ease;
      transform: translateY(20px);
      opacity: 0;
    }
  
    & .button {
      transition: all ${props => props.settings.buttonTransition} ease;
      transform: translate(-50%, 0);
      opacity: 0;
      top: 70%;
      left: 50%;
      line-height: 80%;
  
      position: absolute;
      z-index: 1;
      margin: auto;
      width: 280px;
    }
  
    & section {
      position: absolute;
      bottom: 20px;
      left: 20px;
  
      & * {
         transition: all .3s ease;
      }
    
      & span {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        display: inline-block;
        text-align: left;
        line-height: 1.4;
        vertical-align: middle;
        margin-left: 10px;
        transform: translateX(-10px);
        opacity: 0;
    
      & strong {
        color: #FFFFFF;
        font-size: 14px;
        display: block;
        transform: translateY(10px);
        opacity: 0;
        }
      }
    
      & img {
        width: 40px;
        height: 40px;
        border: solid 2px rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        vertical-align: middle;
        transition: all .5s ease;
        transform: translateX(-20px);
        opacity: 0;
      }
  }
`

export default HeroSlider
