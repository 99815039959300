import Img from 'gatsby-image';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import LinkButton from './LinkButton';
import SubTitleBlue from './SubTitleBlue';

// NOTE: keep these all inline elements so the a tag is still valid
const Title = styled.h2`
  font-size: 65px;
  font-weight: 600;
  text-transform: uppercase;  
  margin-bottom: .5em;
  position: relative;
  color: #fff;
  font-family: ${props => props.theme.fonts.titleFontface};

  @media (max-width: ${props => props.theme.responsive.medium}){
    font-size: 36px;
    font-weight: 600;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-left: 0;
    margin-top: 1em;
    width: inherit;
    text-align: center;
    padding: 30px;
  }
`

const Box = styled.div`
  display: flex;
  color:#fff;
  text-decoration: none;
  flex-wrap: wrap;  
  min-height: 280px;
  background: #151515;
  border-top: 5px solid ${props => props.theme.colors.primary};
  min-height: 280px;
  max-width: 1200px;
  width: 94%;
  margin: auto;
  margin-top: 4%;
  box-shadow: 0 0 3px #00000030;
  position: relative;
  
  .gatsby-image-wrapper {    
    max-width: 456px;
    width: 100%;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      max-width: none;      
    }
  }
  
  
  &:last-of-type {
    border-bottom: none;
  }
  
`

const ButtonContainer = styled.div`
  min-width: 180px;
  max-width: max-content;
  height: 57px;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin: auto;
  }
`

const Descrption = styled.div`
  p {
    line-height: 175%;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      line-height: 150%;
    }
  }
`


const ImageCardBlock = props => {
  const slug = get(props, 'pageLink.slug', null);

  let link = props.learnmore;
  if (slug) {
    link = ('/' + slug);
  }

  return (
    <Box id={props.slug}>
      {props.image ? <Img fluid={props.image.fluid} alt={props.image.title} /> : null }
      <Content>
        <div>
          {props.subTitle ?<SubTitleBlue>{props.subTitle}</SubTitleBlue> : null }
          {props.title ?<Title>{props.title}</Title> : null }
          {props.body ?
          <Descrption><div dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }} /></Descrption>
          : null}
        </div>

        {link ?
        <ButtonContainer>
          <LinkButton href={link} >Learn More</LinkButton>
        </ButtonContainer>
        : null}

      </Content>
    </Box>
  )
}

export default ImageCardBlock
