import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import HeaderSectionBgImg from '../new-images/section-bg-design.png';

const BottomTriangle = styled.div`
  z-index: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 70px 50vw 0 50vw;
  border-color: #161616 transparent transparent;
  position: absolute;
  margin-top: 0;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    border-width: 30px 50.1vw 0 50vw;
    border-color: #161616 transparent transparent;
  }
`

const SectionBlock = styled.div`
  background: #161616 url(${props => props.image});
  position: relative;
  background-position: center;
  background-repeat: repeat;
  background-size: 375px;
  text-align: center;
  box-shadow: inset 0 -214px 134px -5px #161616;
  color: white;
`

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fonts.sectionTitleFont.size};
  padding: 80px 0 25px 0;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  margin:auto;
  
  sup {
    font-weight: 600;
    vertical-align: 17px;
    font-size: 45px;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 25px;
    }
  }


  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 600;
    width: 95%;
    line-height: 50px;
    padding-top: 40px;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
`

const SectionSubTitle = styled.div`
  font-size: ${props => props.theme.fonts.sectionSubTitleFont.size};
  padding: 0 0 100px 0;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  margin:auto;
  color: ${props => props.theme.colors.primary};
  line-height: 45px;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 800;
    width: 90%;
    padding: 0 0 100px 0;
  }
  
`
const Description = styled.div`
 display: flex;
 flex-direction: column;
 align-content: center;
  font-family: ${props => props.theme.fonts.sectionDescriptionFont.family};
  font-size: ${props => props.theme.fonts.sectionDescriptionFont.size}; 
  word-spacing: 4px;
  line-height: 1.4;
  padding: 0 30px 30px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: ${props => props.theme.responsive.small}) {  
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {  
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
`


const LogoWrapper = styled.div`
  display: flex;
  width: 53%;
  margin: auto;
  margin-bottom: 30px;
  justify-content: space-evenly;
  align-items: baseline;

    @media (max-width: ${props => props.theme.responsive.medium}) {
      width: 66%;
      justify-content: space-between;
    }

    @media (max-width: ${props => props.theme.responsive.small}) {
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .logo-with-text {
      .caption {
        font-weight: 600;
        padding: 15px;
        font-size: 34px;
        font-family: ${props => props.theme.fonts.h3Font.family};
        font-weight: 400;
      }
    }


`
const Logo = styled(Img)`
  max-width: 108px!important;
  max-height: 100px!important;
  width: 100%;
  object-fit: scale-down;
  object-position: bottom;
  margin: auto;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    max-width: 88px!important;
    max-height: 80px!important;
  }

`


const SectionDescriptionBlock = props => {
  const content = !props.content ? null : props.content.childMarkdownRemark.rawMarkdownBody;
  const imageOne = !props.imageOne ? null : props.imageOne;
  const imageTwo = !props.imageTwo ? null : props.imageTwo;
  const imageThree = !props.imageThree ? null : props.imageThree;
  const subtitle = !props.subtitle ? null : props.subtitle;

  return (props.title ?
    <SectionBlock image={HeaderSectionBgImg}>
      <SectionTitle  dangerouslySetInnerHTML={{ __html: props.title}} />
      {
        (imageOne && imageTwo && imageThree) ?
        <LogoWrapper>
          <div className="logo-with-text">
            {!imageOne ? null : <Logo
              fluid={imageOne.fluid}
              alt={imageOne.title}
            />
            }
            {!imageOne ? null : <div className="caption">{imageOne.title}</div>}
          </div>
          <div className="logo-with-text">
            {!imageTwo ? null : <Logo
              fluid={imageTwo.fluid}
              alt={imageOne.title}
            />
            }
            {!imageTwo ? null : <div className="caption">{imageTwo.title}</div>}
          </div>
          <div className="logo-with-text">
            {!imageThree ? null : <Logo
              fluid={imageThree.fluid}
              alt={imageOne.title}
            />
            }
            {!imageThree ? null : <div className="caption">{imageThree.title}</div>}
          </div>
        </LogoWrapper>
        : null
      }
      {
        !subtitle ? null: (
          <SectionSubTitle dangerouslySetInnerHTML={{ __html: subtitle}} />
        )
      }
      {!content ? null
        :
        <>
          <Description dangerouslySetInnerHTML={{ __html: content}} />
        </>
      }
      <BottomTriangle />
    </SectionBlock>
    : null)
}


export default SectionDescriptionBlock
