import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import LinkButton from './LinkButton';

const Title = styled.div`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 600;  
  text-transform: uppercase;
  transition: 0.2s;
  color: #404040;
  text-decoration: none;
  display: block;

  &::after {
    width: 66px;
    content: '';
    display: block;
    margin: 25px auto;
    border-bottom: 3px solid ${props => props.theme.colors.primary};
  }




  &.largeTitle {
    font-size: 65px;
    font-family: ${props => props.theme.fonts.h1Font.family};
    font-weight: 600;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 36px;
      top: 46%;
      p {
        font-size: 18px;
      }
    }

    
  }
  
  @media (max-width: ${props => props.theme.responsive.large}) {
    font-size: 1.75em;
  }
  
`

const TopImageTall = styled.span`
  width: 100%;
  height: 200px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s;
  display: block;
  backface-visibility: hidden; /* prevent jump effect when scaling */
  
  &:hover {
    transform: scale(1.1);
  }
`

const Content = styled.span`
  padding: 1.5em;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  &.iconAlignment {
    display: block;
  }
  .flexBox {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`

const IconLine = styled.span`
  display: block;
  height: 12px;
  background-color: ${props => props.theme.colors.base};
  position: absolute;
  width: 100%;
  top: 160px;
  z-index: 1;
  
  &::before {
    content: '';
    display: block;
    background-size: contain;
    background-image: url(${props => props.image.fixed.src});
    background-repeat: no-repeat;
    margin: -30px auto 0;
    width:66px;
    height:66px;
  }
`

const IconBox = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;  
  min-width: 250px;
  max-width: 360px;
  width: 100%;
  min-height: 27em;
  overflow: hidden;
  background-color: white;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  border-top: 5px solid ${(props) => props.theme.colors.primary};
  height: 100%;
    
  @media (max-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 2em;
    height: 100%;
    max-width: none;
    
    // last 2 items in bottom row remove bottom margins (assumes always 2 cols )  
    &:nth-last-child(-n+2) {
      margin-bottom: 2em;
    }
    
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    max-width: none;  
    height: auto;
    
    // last 2 items in bottom add back bottom margins (assumes always 2 cols )  
    &:nth-last-child(-n+2) {
      margin-bottom: 2em;
    }
  }
`

const ButtonContainer = styled.div`
    margin: 0 auto;
    min-width: 130px;
    width: max-content;
    padding: 27px 0 0 0;
    margin-bottom: 27px;
    
    @media (max-width: ${props => props.theme.responsive.medium}) {
      margin: 0 auto;
      padding: 17px 0 0 0;
      font-size: 18px;
      width: max-content;
    }
  `

const InfoBox = props => {
  let bgimg = null;
  if (props.image) {
    bgimg = props.image;
  }
  if (typeof bgimg === 'object' && bgimg && bgimg.fluid ) {
    bgimg = bgimg.fluid.src;
  }

  const title = props.title;
  // const subTitle = props.subTitle;

  const slug = get(props, 'pageLink.slug', null);
  let link = props.learnmore;
  if (slug) {
    link = ('/' + slug);
  }

  return (
    <IconBox id={props.slug}>
      <div>
        <TopImageTall style={{ backgroundImage: `url(${bgimg})` }} />
        {props.icon ? <IconLine image={props.icon} /> : ''}
      </div>
      <Content className="iconAlignment">
        <div className="flexBox">
        <div>
          <Title className="largeTitle">{title}</Title>
          <div>{props.children}</div>
        </div>

        {link ?
          <ButtonContainer>
            <LinkButton variant="primary" href={link}>Learn more</LinkButton>
          </ButtonContainer>
          : null}

        </div>
      </Content>
    </IconBox>
  )
}

export default InfoBox
