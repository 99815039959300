import React from 'react'
import styled from 'styled-components'
import { renderBlock } from '../utils/render-fns'
import RoundCutFooter from '../RoundCutFooter'

const Wrapper = styled.div`
  display: flex;  
  justify-content: space-between;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    flex-wrap: wrap;
  }
  
`
const ContentLeft = styled.div`
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
    flex: 1; 
  }
`
const ContentRight = styled.div`  
  flex-basis: 200%;
  margin-left:1em;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    
    margin-left:0; 
  }
`

const TwoThirdsColumn = props => {
  const blocks = props.blocks;
  return (
    <>
    <Wrapper>
      <ContentLeft>
        { renderBlock(blocks[0], 1) }
      </ContentLeft>
      {blocks[0] ? <ContentRight>
        { renderBlock(blocks[1], 2) }
      </ContentRight> : null }
    </Wrapper>
    {
        !props.section.roundCutFooter ? null:
        <RoundCutFooter backgroundColor={props.section.backgroundColor}/>
      }

    </>
  )
}


export default TwoThirdsColumn
