import React from 'react'
import styled from 'styled-components'
import { renderBlocks } from '../utils/render-fns';
import RoundCutFooter from '../RoundCutFooter';
import Img from 'gatsby-image';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 60px;
  //grid-template-columns: repeat(2, 1fr);
  //grid-template-columns: repeat(auto-fill, 186px);
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  
  margin-bottom: ${props => props.overflowSection ? '-100px' : '1em'};
  padding-top: ${props => props.paddingTop};
  @media (max-width: ${props => props.theme.responsive.small}) {
      grid-template-columns: 1fr;
      grid-gap: 30px;
  }
`

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fonts.sectionTitleFont.size};
  font-weight:  ${props => props.theme.fonts.sectionTitleFont.weight};
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  margin:auto;
  line-height: 80px;
  text-align: center;
  color: #ffffff;
  padding-top: 22px;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 600;
    width: 95%;
    line-height: 50px;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
`

const PrimaryColorLine = styled.div`
  height: 6px;
  width: 290px;
  background-color: ${props => props.theme.colors.primary};
  margin: auto;
  margin-top: 25px;
  margin-bottom: 35px;
`

const TwoColumn = props => {
  const sectionHeader = props.section.displayTitle && (
    <>
      <SectionTitle
        dangerouslySetInnerHTML={{ __html: props.section.displayTitle }}
      />
      <PrimaryColorLine />
    </>
  )

  return (
    <div id={props.id}>
      {props.section && props.section.headerImage ? <Img className="full-width" fluid={props.section.headerImage.fluid} alt={props.section.headerImage.title} /> : null}

      {sectionHeader}
      {props.sectionBody}

      <Wrapper {...props} >
        { renderBlocks(props.blocks) }
      </Wrapper>
      {
        !props.section.roundCutFooter ? null:
        <RoundCutFooter backgroundColor={props.section.backgroundColor}/>
      }
    </div>
  )
}

export default TwoColumn
