import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 1;
  overflow: hidden;
  min-width: 300px;
  min-height: 700px;

  @media (max-width: ${props => props.theme.responsive.small}) {
    min-height: 580px;
  }
`;

class JotForm extends React.Component {
  render() {
    return (
      <Container>
        <JotformEmbed src={this.props.src} />
      </Container>
    )
  }
}

export default JotForm
