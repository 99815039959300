import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

class Phone extends React.Component{
  render() {
    return (
      <StaticQuery
        query={graphql`
            query {
                contentfulGlobalContent(slug:{eq:"phone-number"}) {
                  shortText
              }
            }
        `}
        render={data => {
          const phone = data.contentfulGlobalContent.shortText;
          return (
            <>
              <a href={`tel:${phone}`}>{this.props.children} {phone}</a>
            </>
          )
        }}
      />
    );
  }
}

export default Phone
