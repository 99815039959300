import React from 'react'
import styled from 'styled-components'
import Hero from '../../Hero'
import HeaderTop from './HeaderTop'
import Menu from './Menu'

const HeaderTag = styled.header`
  z-index: 5;
  position: absolute;
  width: 100%;  
`

const Header = ({ data }) => {
  let heroImage = null;
  let title = ''
  if (data) {
    heroImage = data.heroImage;
    title = !heroImage?.title ? null : heroImage.title;
  }

  return (
    <HeaderTag>
      <HeaderTop />
      <Menu />
      {heroImage ? null : <Hero img={heroImage} alt={title} />}
    </HeaderTag>
  )
}

export default Header