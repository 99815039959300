import React from 'react';
import styled from 'styled-components';

const Title = styled.span`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 600;  
  transition: 0.2s;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  display: block;
  font-size: 22px;
  line-height: 130%;
  @media (max-width: ${props => props.theme.responsive.large}) {
    font-size: 1.75em;
  }
`

const Date = styled.div`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 400;  
  transition: 0.2s;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  padding: 15px 0;

  @media (max-width: ${props => props.theme.responsive.large}) {
    font-size: 1.75em;
  }
`

const TopImageTall = styled.span`
  width: 100%;
  height: 215px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s;
  display: block;
  backface-visibility: hidden; /* prevent jump effect when scaling */
  `

const Description = styled.div`
  font-family: ${props => props.theme.fonts.titleFontface};
  font-weight: 300;
  p {
    font-size:20px;
    color: ${props => props.theme.colors.base}
  }
`
const Content = styled.span`
  padding: 1.2em .6em;
  margin-bottom: 25px;  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;

  &.iconAlignment {
    display: block;
  }
`

const IconLine = styled.span`
  display: block;
  height: 12px;
  background-color: ${props => props.theme.colors.base};
  position: absolute;
  width: 100%;
  top: 160px;
  z-index: 1;
  
  &::before {
    content: '';
    display: block;
    background-size: contain;
    background-image: url(${props => props.image.fixed.src});
    background-repeat: no-repeat;
    margin: -30px auto 0;
    width:66px;
    height:66px;
  }
`

const IconBox = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;  
  overflow: hidden;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  min-width: 174px;
  max-width: 372px;
  border: 1px solid #bbbbbb;
  padding: .5em;
  background: #f0f0f0;
  border-radius: 4px;
  height: 100%;

  @media (max-width: ${props => props.theme.responsive.large}) {  
    margin-bottom: 2em;
    
    // last 2 items in bottom row remove bottom margins (assumes always 2 cols )  
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
    
  }
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    max-width: none;  
    height: auto;
    width: 100%;
    
    // last 2 items in bottom add back bottom margins (assumes always 2 cols )  
    &:nth-last-child(-n+2) {
      margin-bottom: 2em;
    }
    
    // last item remove bottom margin to override above
    &:last-child {
      margin-bottom: 0;
    }
  }
`


const EventCard = props => {
  let bgimg = null;
  if (props.image) {
    bgimg = props.image;
  }
  if (typeof bgimg === 'object' && bgimg ?.fluid) {
    bgimg = bgimg?.fluid.src;
  }

  const eventTitle = !props.title  ? null : props.title;
  const datePosted = !props.datePosted ? null : props.formatDate(props.datePosted);

  return (
    <IconBox id={props.slug}>
      <div>
        <TopImageTall style={{ backgroundImage: `url(${bgimg})` }} />
        {props.icon ? <IconLine image={props.icon} /> : ''}
      </div>
      <Content className="iconAlignment">
        <div>
          <Title>{eventTitle}</Title>
          <Date>{datePosted}</Date>
        </div>
          {!props.description ? null :
        <Description dangerouslySetInnerHTML={{ __html: props.description.childMarkdownRemark.html }}/>
          }

      </Content>
    </IconBox>
  )
}

export default EventCard
