/* put all shared render logic here */

import { Link } from 'gatsby'
import { get } from 'lodash'
import React from 'react'
import rehypeReact from 'rehype-react'
import styled from 'styled-components'
import Container from '../Container'
import AlignText from '../custom/AlignText'
import BorderText from '../custom/BorderText'
import BorderTextContainer from '../custom/BorderTextContainer'
import JotForm from '../custom/JotForm'
import ResponsiveIframe from '../custom/ResponsiveIframe'
import EventCard from '../EventCard'
import Events from '../events/Events'
import ExclusiveBoxes from '../ExclusiveBoxes'
import FormBioBox from '../FormBioBox'
import Video from '../Video'
import IconBoxDescription from '../IconBoxDescription'
import ImageCardBlock from '../ImageCardBlock'
import BannerImageBlock from '../BannerImageBlock'
import ImageDescriptionBlock from '../ImageDescriptionBlock'
import ImageDescriptionBlockVertical from '../ImageDescriptionBlockVertical'
import InfoBox from '../InfoBox'
import Grid from '../page_section/Grid'
import SmallTwoColumn from '../page_section/SmallTwoColumn'
import ThreeColumn from '../page_section/ThreeColumn'
import TwoColumn from '../page_section/TwoColumn'
import TwoThirdsColumn from '../page_section/TwoThirdsColumn'
import PhotoBoxButton from '../PhotoBoxButton'
import SectionDescriptionBlock from '../SectionDescriptionBlock'
import SectionRow from '../SectionRow'
import SectionWithUnderlineTitle from '../SectionWithUnderlineTitle'
import VideoBox from '../VideoBox'
import PhoneIcon from '../custom/PhoneIcon'
import Photo from '../Photo'
import ContactCardBlock from '../ContactCardBlock'
import SkidcarTeam from '../SkidcarTeam'
import PartsCard from '../PartsCard'
import AccordionListContainer from '../FaqField'
import DescriptionBlock from '../DescriptionBlock'
import RoundCutFooter from '../RoundCutFooter'

const NewSection = styled.section`
  transition: opacity 500ms ease-in, transform 500ms ease-in;
  will-change: opacity, visibility;
  visibility: ${props => props.isvisible ? 'visible' : 'hidden'};
  opacity: ${props => props.isvisible ? 1 : 0};
`

const FadeInSection = ({
  children,
}) => {
  const domRef = React.useRef();

  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      // In your case there's only one element to observe:
      if (entries[0].isIntersecting) {
        // Not possible to set it back to false like this:
        setVisible(true);
        // No need to keep observing:
        if (domRef.current) {
          observer.unobserve(domRef.current);
        }
      }
    });

    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    }
  }, []);

  return <NewSection ref={ domRef } isvisible={isVisible}>{ children }</NewSection>;
};

export const renderAst = new rehypeReact({ // eslint-disable-line new-cap
  createElement: React.createElement,
  components: {
    "phone-icon": PhoneIcon,
    "align-text": AlignText,
    "responsive-iframe": ResponsiveIframe,
    "jot-form": JotForm,
    // Border Text
    "border-text-container": BorderTextContainer,
    "border-text": BorderText
  },
}).Compiler

export const wrapLink = (pageLink = null, linkPath = null, linkUrl = null, directFile = null, component) => {

  let isInternal = false;
  let internalLink = '';
  let externalLink = '';

  if (pageLink) {
    isInternal = true;
    internalLink += '/' + pageLink.slug;
  }
  else if (linkPath) {
    if (linkPath.startsWith('#')) {
      isInternal = false;
      externalLink = linkPath;
    }
    isInternal = true;
    internalLink = linkPath;
  }
  else {
    if (linkUrl || directFile) {
      isInternal = false;
      externalLink = linkUrl || get(directFile, 'file.url', null);
    }
  }

  if (isInternal && internalLink) {
    return (
      <Link key={Math.random(12)} to={internalLink}>{component}</Link>
    )
  } else if (!isInternal && externalLink) {
    return (
      <a key={Math.random(12)} rel="noopener noreferrer" target="_blank" href={externalLink}>{component}</a>
    )
  }
  return component;
}

export const renderBlock = (block, blockIndex) => {

  // console.log('block: ', block);

  if (block.__typename === 'ContentfulBannerImage') {
    return (
      <BannerImageBlock key={`section${blockIndex}`} {...block} />
    )
  }

  if (block.__typename === 'ContentfulPageIntro') {
    return (
      <SectionDescriptionBlock key={`section${blockIndex}`} {...block} />
    )
  }

  if (block.__typename === 'ContentfulContactCard') {
    return (
      <ContactCardBlock key={`section${blockIndex}`} {...block} />
      )
    }

  if (block.__typename === 'ContentfulSkidcarTeam') {
    return (
      <SkidcarTeam key={`section${blockIndex}`} {...block} />
    )
  }

  if (block.__typename === 'ContentfulForm') {
    // remove parent p tag to avoid rendering warnings about iframe in div
    block.form.childMarkdownRemark.htmlAst.children[0].tagName = 'div';
    return renderAst(block.form.childMarkdownRemark.htmlAst)
  }

  switch (block.featureType) {
    case 'Form Bio Box':
      return <FormBioBox {...block} />

    case 'Photo Box Button':
      return <PhotoBoxButton {...block} key={`block${blockIndex}`} linkPath={block.linkPath} />

    case 'Video':
      return <Video url={block.videoUrl} key={`block${blockIndex}`} />

    case 'Photo':
      return <Photo image={block.image} key={`block${blockIndex}`} />

    case 'Image Card Block':
      return <ImageCardBlock slug={block.slug} key={`block${blockIndex}`} image={block.image} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} body={block.body} gallery={block.gallery} learnmore={block.linkPath} pageLink={block.pageLink} />

    case 'Image desc block left':
      return <ImageDescriptionBlock direction="left" slug={block.slug} key={`block${blockIndex}`} image={block.image} imageCaption={block.image && block.image.description} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} body={block.body} gallery={block.gallery} learnmore={block.linkPath} pageLink={block.pageLink} />

    case 'Image desc block right':
      return <ImageDescriptionBlock direction="right" key={`block${blockIndex}`} image={block.image} imageCaption={block.image && block.image.description} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} body={block.body} gallery={block.gallery} learnmore={block.linkPath} pageLink={block.pageLink} />

    case 'Image desc block top':
      return <ImageDescriptionBlockVertical direction="top" slug={block.slug} key={`block${blockIndex}`} image={block.image} imageCaption={block.image && block.image.description} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} body={block.body} gallery={block.gallery} learnmore={block.linkPath} pageLink={block.pageLink} />

    case 'Image desc block bottom':
      return <ImageDescriptionBlockVertical direction="bottom" key={`block${blockIndex}`} image={block.image} imageCaption={block.image && block.image.description} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} body={block.body} gallery={block.gallery} learnmore={block.linkPath} pageLink={block.pageLink} />

    case 'Video Box':
      return <VideoBox slug={block.slug} key={`block${blockIndex}`} image={block.image} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} videoUrl={block.linkUrl} year={block.year} />
    case 'Info Box':
      return (
        <InfoBox slug={block.slug} key={`block${blockIndex}`} icon={block.icon} image={block.image} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} learnmore={block.linkPath} pageLink={block.pageLink} >
          {block.body ? <div>{renderAst(block.body.childMarkdownRemark.htmlAst)}</div> : null}
        </InfoBox>
      )
    case 'Icon Box':
      return (
        <IconBoxDescription slug={block.slug} body={block.body} key={`block${blockIndex}`} icon={block.icon} image={block.image} title={block.displayTitle} subTitle={block.displaySubTitle || block.subTitle} learnmore={block.linkPath} pageLink={block.pageLink} />
      )
    case 'event card':
      return (
        <EventCard slug={block.slug} key={`block${blockIndex}`} {...block}/>
      )

    case 'parts card':
      return (
        <PartsCard slug={block.slug} key={`block${blockIndex}`} {...block} />
      )
    case 'Description':
      return (
        block.body ? <DescriptionBlock slug={block.slug} {...block} /> : null
      )

    default:
      return (
        <div id={block.slug} key={`block${blockIndex}`}>
          {block.displayTitle ? <h2>{block.displayTitle}</h2> : null}
          {block.displaySubTitle || block.subTitle ? <h3>{block.displaySubTitle || block.subTitle}</h3> : null}
          {!block.image ? null : wrapLink(block.pageLink, block.linkPath, block.linkUrl, block.directFile, <img src={block.image.fluid.src} />)}
          {!block.body ? null : <div>{renderAst(block.body.childMarkdownRemark.htmlAst)}</div>}
        </div>
      )
  }
}

export const renderBlocks = (blocks) => {
  return !blocks ? null :
    blocks.map((block, blockIndex) => {
      return (
        <FadeInSection key={ blockIndex } >
          {renderBlock(block, blockIndex)}
        </FadeInSection>
      )
    })
}

const Wrapper = styled.div`  
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 2em 1em;
  @media (max-width: ${props => props.theme.responsive.small}) {
    ${props => props.paddingTop ? 'padding-top: 30px' : ''};
    padding-bottom: 1em;
  }
  padding-top: ${props => props.paddingTop ? '100px' : '0'};
`
const Footer = styled.div`  
  margin: 2em 0;
`


export const renderSections = (sections, container = false) => {
  return container ? (
    !sections ? null :
      sections.map((section, index) => {

        return (
        section.sectionType === 'page intro' ? renderSection(section)
            :
            <Container key={`section${index}`} section={section}>
              <Wrapper  paddingTop={section.paddingTop}>
              <FadeInSection key={ index } >
                {renderSection(section)}
                {!section.footer ? null : section.footer.childMarkdownRemark.html ? <Footer><div dangerouslySetInnerHTML={{ __html: section.footer.childMarkdownRemark.html }} /></Footer> : null}
            </FadeInSection>
              </Wrapper>
            </Container>

        )
      })
  ) : (
      !sections ? null :
        sections.map((section, index) => {
          return (
            section.sectionType === 'page intro' ? renderSection(section)
              :
              <div style={{ background: section.backgroundColor }}>
              <Wrapper key={`section${index}`}>
                <FadeInSection key={ index } >
                {renderSection(section)}
                {!section.footer ? null : <Footer><div dangerouslySetInnerHTML={{ __html: section.footer.childMarkdownRemark.html }} /></Footer>}
              </FadeInSection>
              </Wrapper>
              </div>
          )
        })
    )
}

export const renderSection = (section) => {

  const sectionHeader = (
    <>
      {section.displayTitle || section.title ? <h2 id={section.slug}>{section.displayTitle || section.title}</h2> : ''}
      {section.displaySubTitle || section.subTitle ? <h3>{section.displaySubTitle || section.subTitle}</h3> : ''}
    </>
  )
  const sectionBody = (
    <>
      {!section.body ? null : <div>{renderAst(section.body.childMarkdownRemark.htmlAst)}</div>}
    </>
  )
  switch (section.sectionType) {
    case 'exclusives':
      return (
        <ExclusiveBoxes slug={section.slug} section={section} title={section.displayTitle || section.title} subtitle={section.displaySubTitle || section.subTitle}>
          {
            renderBlocks(section.contentBlock)
          }
        </ExclusiveBoxes>
      )
    case 'grid':
      return (
        <Grid section={section} slug={section.slug} title={section.displayTitle || section.title} subtitle={section.displaySubTitle || section.subTitle}>
          {
            renderBlocks(section.contentBlock)
          }
        </Grid>
      )
    case 'FAQ':
      return (
        <AccordionListContainer slug={section.slug} blocks={section.contentBlock} />
      )
    case 'two column':
      return (
          <TwoColumn id={section.slug} section={section} sectionHeader={sectionHeader} sectionBody={sectionBody} blocks={section.contentBlock} overflowSection={section.overflowSection}/>
      )
    case 'small two column':
      return (
        <div id={section.slug} className="margin-bottom-100 padding-top-50">
          {sectionHeader}
          {sectionBody}
          <SmallTwoColumn section={section}  blocks={section.contentBlock} overflowSection={section.overflowSection} paddingTop={section.paddingTop}/>
        </div>
      )
    case 'three column':
      return (
        <div id={section.slug}>
          {sectionHeader}
          {sectionBody}
          <ThreeColumn blocks={section.contentBlock} section={section} />
        </div>
      )
    case '2/3rds Column':
      return (
        <div id={section.slug}>
          {sectionHeader}
          <TwoThirdsColumn blocks={section.contentBlock} section={section}/>
        </div>
      )
    case 'row':
      return (
        <SectionRow
          section={section}
          sectionHeader={sectionHeader}
          slug={section.slug}
        />
      )
    case 'page intro':
      return (
        renderBlocks(section.contentBlock)
      )
    case 'section with underline title':
      return (
        <SectionWithUnderlineTitle slug={section.slug} section={section}>
          {renderBlocks(section.contentBlock)}
        </SectionWithUnderlineTitle>
      )
    case 'events':
      return (
        <ExclusiveBoxes slug={section.slug} section={section} >
          <Events/>
        </ExclusiveBoxes>
      )
    default:
      return (
        <div id={section.slug}>
          {sectionHeader}
          {!section.body ? null : <div style={{ height: '100%' }}>{renderAst(section.body.childMarkdownRemark.htmlAst)}</div>}
          {!section.contentBlock ? null :
            section.contentBlock.map((block, blockIndex) => {
              return renderBlock(block, blockIndex)
            })}
          {
            !section.roundCutFooter ? null:
            <RoundCutFooter backgroundColor={section.backgroundColor}/>
          }

        </div>
      )
  }
}
