import React from 'react';
import styled from 'styled-components';
import { FaPhoneAlt } from 'react-icons/fa';  

const Container = styled.span`

    svg {
        background: ${props => props.theme.colors.primary};
        border-radius: 50px;
        padding: 4px;
        width: 33px;
        height: 33px;
        fill: #ffffff;
        margin: 0 12px;
        @media (max-width: ${props => props.theme.responsive.small}) {
            width: 23px;
            height: 23px;
        }
    }
  
`

const PhoneIcon = props => {
return <Container><FaPhoneAlt/></Container>
}

export default PhoneIcon
