import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import ModalVideo from 'react-modal-video'
import getVideoId from 'get-video-id'
import {
  FaPlayCircle
} from 'react-icons/fa';

const Wrapper = styled.div`
  //height: 210px;
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-bottom: 20px;
    max-width: none;
  }
`

const Year = styled.span`
  
`
const PlayIcon = styled.span`
  
`

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;  
  width: 100%;
  height: 175px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  cursor: pointer;
    
  &:hover::before {
    transform: scale(1.1);
  }
  
  .inner {
    transition: background-color 300ms linear; 
    background-color: rgba(39, 39, 39, 0.35);    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    .title {
      vertical-align: bottom;
      margin: 10px;
      display: flex;
      color:white;
      font-size: 1.8em;
      justify-content: space-between;
      font-family: ${props => props.theme.fonts.h1Font.family};
      font-weight: ${props => props.theme.fonts.h1Font.weight};
      
      ${Year} {
        align-self: flex-end;
      }
      
      ${PlayIcon} {
        opacity: .5;
        font-size: 1.8em;
      }
      
    }
    
  }
  
  &:hover {
    .inner {
      background-color: rgba(39, 39, 39, 0.65);
    }
  }
  
  &::before {
    backface-visibility: hidden; /* prevent jump effect when scaling */
    content: '';    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    transition: 1s;
    background-image: url(${props => props.bgimg});
    background-size: cover;
    background-repeat: no-repeat;    
  }
 
`

const Content = styled.div`
  width: 100%;
  color: white;
  font-weight: 700;
  padding: .5em;
  background-color: ${props => props.theme.colors.base};
  
  p {
    margin: 0;
  }
  
`



class VideoBox extends React.Component {

  constructor (props) {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)

    this.bgimg = null;
    if(props.image) {
      this.bgimg = props.image;
    }
    if(get(this.bgimg, 'fluid')) {
      this.bgimg = this.bgimg.fluid.src;
    }

    const {id, service} = getVideoId(props.videoUrl);
    this.videoID = id;
    this.service = service;
    this.slug = props.slug;
  }

  openModal () {
    this.setState({isOpen: true})
  }


  render () {
    return (
      <Wrapper id={this.slug}>
        <ModalVideo channel={this.service} isOpen={this.state.isOpen} videoId={this.videoID} onClose={() => this.setState({ isOpen: false })}/>
        <Box onClick={this.openModal} bgimg={this.bgimg}>
          <div className="inner">
            <div className="title">
              <Year>{this.props.year}</Year>
              <PlayIcon><FaPlayCircle/></PlayIcon>
            </div>
          </div>
        </Box>
        <Content>
          <p>{this.props.title}</p>
          <p>{this.props.subTitle}</p>
        </Content>
      </Wrapper>
    )
  }
}

export default VideoBox
