import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image';
import React from 'react';
import { FaFacebook, FaInstagram, FaMobileAlt, FaRegCommentAlt, FaTwitter, FaYoutube } from 'react-icons/fa';
import styled from 'styled-components';
import Phone from '../../Phone';

const HeaderTopDiv = styled.div`
  background: ${props => props.theme.colors.base};
  padding: 0.5em 0.5em;
  text-align: right;
  text-transform: uppercase;
  color: white;
  font-family: ${props => props.theme.fonts.topMenuFont.family};
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    display:none;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.HeaderTextColor};
    margin-right: 1rem;
  }
  
  //svg {
  //  position: relative;
  //  top: .2em;
  //}
  //
  span {
    margin-right: 1rem;
  }
`

const Container = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 100%;
  padding: 0 1.5em;
  display: flex;

  .youtube {
    font-size: 1.4em; 
    top: 1.5px;
    position: relative;
  }
`

const HeaderMenuLinks = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 100%;
  padding: 2px 1.5em;
  display:flex;
  justify-content: flex-end;

  > a {
    font-size: 18px;
    color: ${props => props.theme.colors.HeaderTextColor}!important;
    align-items: center;
    justify-content: center;
    display: flex;
    svg {
      padding: 3px;
      width: 28px;
      height: 21px;
    }

  }
`
const SocialMediaLinks = styled.div`
  display: flex;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding-left: 20%;
  >a {
    svg {
      background: grey;
      border-radius: 50px;
      padding: 3px;
      width: 25px;
      height: 25px;
      fill: ${(props) => props.theme.colors.base};
    }
  }
  `

const IntlIcon = styled(Img)`
  width: 16px !important;
  height: 16px !important;
  margin: auto 5px;
`


const HeaderTop = () => {
  return (
    <HeaderTopDiv>
      <Container>
        <SocialMediaLinks>
          <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/user/SkidCarSystem" >
            <FaYoutube />
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/SkidcarSystemUSA/">
            <FaFacebook />
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/skidcarsystemusa/?hl=en">
            <FaInstagram />
          </a>
        </SocialMediaLinks>
        <HeaderMenuLinks>
          <Phone><FaMobileAlt /></Phone>
          <StaticQuery
            query={graphql`
                query {
                  logoSkidcarBike: file(relativePath: { eq: "intl-dealers-icon-32x32.png" }) {
                    childImageSharp {
                      fixed(width: 32) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
              }
            `}
            render={data => {
              const img = data.logoSkidcarBike.childImageSharp.fixed;
              return (
                <>
                  <Link to="/contact#international-dealers"><IntlIcon fixed={img}/>INTL DEALERS</Link>
                </>
              )
            }}
          />
          <Link to="/contact"><FaRegCommentAlt /> Contact Us</Link>
        </HeaderMenuLinks>
      </Container>
    </HeaderTopDiv>
  )
}

export default HeaderTop
