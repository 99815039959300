import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    left: ${props => props.left};
    right: ${props => props.right};
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    display: flex;
    flex-direction: column;
    position: fixed;
    
    @media (max-width: ${props => props.theme.responsive.medium}) {
        left: auto;
        right: 30px;
        top: 60px;
        position: absolute;
    }
`

const BorderTextContainer = props => {
    return <Container {...props}>{props.children}</Container>
}

export default BorderTextContainer