import { Link } from 'gatsby';
import React from 'react';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import styled from 'styled-components';

const Button = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 36px 20px 36px;  
  align-self: center;  
  text-transform: uppercase;
  border-radius: 3px;   
  font-family: ${props => props.theme.fonts.buttonFont.family};
  font-weight: ${props => props.theme.fonts.buttonFont.weight};
  transition: 0.2s;
  cursor: pointer;  
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.buttonTextColor};
  overflow: hidden;
  font-size: 18px;
  letter-spacing: 5px;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    letter-spacing: 3px;
    padding: 10px 26px 10px 26px;
    font-size: 15px;
  }

  
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  span.icon {
    margin-left: .5em;    
  }
  
  &:hover, &:active {
    background: ${props => props.theme.colors.primary};
    color:white;
    
    a {
        &:hover, &:active {
          color:white;
        }
    }
  }
  
  &.secondary {
    background: ${props => props.theme.colors.secondaryButtonBGColor};
    color: ${props => props.theme.colors.secondaryButtonTextColor};
          &:hover, &:active {
          background: ${props => props.theme.colors.secondaryButtonBGColor};
        color: ${props => props.theme.colors.secondaryButtonTextColor};
      }
    a {
        &:hover, &:active {
        background: ${props => props.theme.colors.secondaryButtonBGColor};
          color: ${props => props.theme.colors.secondaryButtonTextColor};
        }
    }
  }
  
  &.nobutton {
    background: transparent;
    padding:0;
    text-align: left;
    display: block;
    
    &:hover, &:active {
      color: inherit;
      
      a {
          &:hover, &:active {
            color: inherit;
          }
      }
      
    }
  }
  
  // tertiary buttons have a box around a different arrow
  &.tertiary {
    background: ${props => props.theme.colors.tertiaryButtonBGColor};
    color: ${props => props.theme.colors.tertiaryButtonTextColor};
    
      a {
          &:hover, &:active {
            color: ${props => props.theme.colors.tertiaryButtonTextColor};
          }
      }
    
    span.icon {          
      position:relative;
      text-align: center; 
      margin-left:16px;  
     
      svg {
        margin-left: 16px;        
        position: relative;
        z-index: 1;
      }
    }
    
    span.icon::before {
      content: "";
      position: absolute;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      left:0;
      bottom:-50px;
      top:-50px;      
      right: -16px;
      background-color: ${props => props.theme.colors.tertiary};
      z-index: 0;
    }
  }
  
  svg {
    margin-top: -2px;
  }
`

const   LinkButton = props => {
  const renderButton = () => {
    if (props.href) {
      return (
        <Button className={props.variant + ' button'}><Link to={props.href}><span>{props.children}</span> <span className="icon">{props.icon ? <FaArrowAltCircleRight /> : ''}</span></Link></Button>
      );
    }
    else
    if (props.externalLink) {
      return (
        <Button className={props.variant + ' button'}><a href={props.externalLink}><span>{props.children}</span> <span className="icon">{props.icon ? <FaArrowAltCircleRight /> : ''}</span></a></Button>
      );
    } else {
      return (
        <Button className={props.variant}><span>{props.children}</span> <span className="icon">{props.icon ? <FaArrowAltCircleRight /> : ''}</span></Button>
      );
    }
  }

  return renderButton(props)
}

export default LinkButton
