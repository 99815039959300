import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { ConditionalWrapper } from './utils/tools'

const BgImageWrapperLink = styled.a`
  display: flex;
  position: relative;
  color:#fff;
  text-decoration: none;
  flex-wrap: wrap;
  background: #151515;
  max-width: 1200px;
  width: 100%;
`;

const BgImageDesktop =  styled(Img)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    @media (max-width: ${props => props.theme.responsive.medium}) {
      max-width: none;
      display: none;
    }
`
const BgImageMobile =  styled(Img)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    @media (min-width: ${props => props.theme.responsive.medium}) {
      max-width: none;
      display: none;
    }
`

const Box = styled.div`
  display: flex;
  position: relative;
  color:#fff;
  text-decoration: none;
  flex-wrap: wrap;
  background: #151515;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin-top: 4%;
  box-shadow: 0 0 3px #00000030;
  
  
  &:last-of-type {
    border-bottom: none;
  }
`


const BannerImageBlock = props => {
  // console.log('BannerImageBlock props: ', props);
  const { slug, desktopImage, mobileImage, link, name } = props;
  return (
    <Box id={slug}>
      <ConditionalWrapper condition={link} wrapper={(children) => <BgImageWrapperLink href={link} rel="noreferrer" target="_blank">{children}</BgImageWrapperLink>}>
        <>
          {desktopImage ? <BgImageDesktop fluid={desktopImage.fluid} alt={name} /> : null }
          {mobileImage ? <BgImageMobile fluid={mobileImage.fluid} alt={name} /> : null }
        </>
      </ConditionalWrapper>
    </Box>
  )
}

export default BannerImageBlock
