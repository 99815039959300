import React from 'react'
import styled from 'styled-components'
import { renderBlock } from './utils/render-fns'
import Video from './Video'
import RoundCutFooter from './RoundCutFooter'
import Img from 'gatsby-image';

const Wrapper = styled.div`
  padding-top: 32px;

  .circle-helper {
    display: block;
    width: 100%;
    padding-bottom: 16%;
    /* bottom: 0; */
    left: 0;
    overflow: hidden;
    position: absolute;
    background-color: transparent;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    z-index: 0;
  }
  .circle {
    display: block;
    width: 140%;
    padding-bottom: 112%;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    background-color: transparent;
    z-index: 99999999;

    @media (max-width: ${props => props.theme.responsive.small}) {
      padding-bottom: 220%;
    }
  }

  .circle:after {
    box-sizing: content-box;
    content: '';
    width: 200%;
    height: 100%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 48%;
    left: 36%;
    border: 132px solid ${props => props.backgroundColor};
    z-index: 999;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      top: 46%;
      // top: 239px;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      top: 46%%;
    }
  }
`

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fonts.sectionTitleFont.size};
  font-weight:  ${props => props.theme.fonts.sectionTitleFont.weight};
  font-family: ${props => props.theme.fonts.sectionTitleFont.family};
  margin:auto;
  line-height: 80px;
  text-align: center;
  color: #ffffff;
  
  @media (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2.375rem;
    font-weight: 600;
    width: 95%;
    line-height: 50px;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
`

const PrimaryColorLine = styled.div`
  height: 6px;
  width: 290px;
  background-color: ${props => props.theme.colors.primary};
  margin: auto;
  margin-top: 25px;
`

const Container = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;

  @media (max-width: ${props => props.theme.responsive.medium}) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const Description = styled.div`
  color: white;
  line-height: 175%;
  text-align: center;
  margin-bottom: 35px;
  font-size: 1.125rem;
`

const SectionWithUnderlineTitle = ({ section, slug }) => {
  const desc = !section.body
    ? null
    : section.body.childMarkdownRemark.rawMarkdownBody
  const sectionHeader = section.displayTitle && (
    <>
      <SectionTitle
        dangerouslySetInnerHTML={{ __html: section.displayTitle }}
      />
      <PrimaryColorLine />
    </>
  )
  return (
    <Wrapper
      id={slug}
      slug={slug}
      paddingTop={section.paddingTop}
      backgroundColor={section.backgroundColor}
    >
      {section && section.headerImage ? <Img className="full-width" fluid={section.headerImage.fluid} alt={section.headerImage.title} /> : null}
      {sectionHeader}
      {section.displaySubTitle || section.subTitle ? (
        <h3>{section.displaySubTitle || section.subTitle}</h3>
      ) : (
        ''
      )}
      <Container>
        {!section.contentBlock
          ? null
          : section.contentBlock.map((block, blockIndex) => {
              return renderBlock(block, blockIndex)
        })}
      </Container>
      {!desc ? null : <Description>{desc}</Description>}
      {!section.videoUrl ? null : (
        <Video url={section.videoUrl}>{section.videoUrl}</Video>
      )}
      {!section.roundCutFooter ? null : (
        <RoundCutFooter backgroundColor={section.backgroundColor} />
      )}
    </Wrapper>
  )
}

export default SectionWithUnderlineTitle
