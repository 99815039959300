module.exports = {
  siteTitle: 'Skidcar',
  siteTitleAlt: 'SKIDCAR & SKIDTRUCK Systems: Complete Driver Training Solutions', // This allows an alternative site title for SEO schema.
  publisher: 'Skidcar', // Organization name used for SEO schema
  siteDescription: 'Complete Driver Training Solutions',
  siteUrl: 'https://www.skidcar.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Skidcar', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.skidcar.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@skidcar1', // Change for Twitter Cards
  shortTitle: 'Skidcar App', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/skidcar-logo-1200x1200.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/skidcar-logo-512x512.jpg', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#00a1e0', // Used for Offline Manifest
  copyright: `Copyright © ${new Date().getFullYear()} SKIDCAR SYSTEM, INC.`, // Copyright string for the RSS feed
}
