import { graphql, StaticQuery } from "gatsby";
import React from 'react';
import EventCard from "../EventCard";


// formats the date like October 10, 2018
const formatDate = (dateStr) => { 
  const strArray=['January', 'February', 'March', 'April', 'May', 'Jun', 'July', 'Auguse', 'September', 'October', 'November', 'December'];
  const theDate = new Date(dateStr);
  const d = theDate.getDate() + 1;
  const m = strArray[theDate.getMonth()];
  const y = theDate.getFullYear();
  return `${m} ${d <= 9 ? '0' + d : d}, ${y}`
}


// eslint-disable-next-line react/display-name
export default () => (

    <StaticQuery
        query={graphql`
            query AllEventsQuery {
              allContentfulEvents(sort: { fields: datePosted, order: DESC } filter:{slug:{ne:"template-do-not-delete"}}) {
                edges {
                  node {
                    ...event
                  }
                }
              }
            }
        `}
        render={data => {
            if (!data) return null
              return data.allContentfulEvents.edges.map((node,index) => {
                const events = node.node
                return(
                  <EventCard key={index} {...events} formatDate={formatDate} />
              )
            })
      }}
    />
)
