import { graphql, StaticQuery } from "gatsby";
import React from 'react';
import styled from 'styled-components';

const FooterEvents = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: ${props => props.theme.sizes.maxWidth};
  @media (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
  }
`

const FooterEventBox = styled.div`
  display: flex;
  //justify-content: space-between;
  color: ${props => props.theme.colors.footerEventTextColor};
  flex-direction: column;
  font-size: 22px;
  padding: 5px;
  text-align: center;
  margin: 25px;
  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 20px;
  }
  >.title {
    color: white;
    margin-bottom: 13px; 
    font-family: ${props => props.theme.fonts.descriptionFontface};
    font-weight: 500;
  }

  >.description {
    font-family: ${props => props.theme.fonts.descriptionFontface};
    line-height: normal;
    color: #a4a4a4;
  }
`

const FooterEventsTitle = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-family: ${props => props.theme.fonts.descriptionFontface};
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 4px;
  
  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 20px;
  }

`

const FooterEventsContainer = (props) => {

  return (
    <StaticQuery
      query={graphql`
    {
      footer_event_title: contentfulGlobalContent(slug: {eq: "footer-events-title"}) {
        shortText
      }
      footer_event_1: contentfulGlobalContent(slug: {eq: "footer-event-box-1"}) {
        shortText
        content {
          childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
          }
        }
      }
      footer_event_2: contentfulGlobalContent(slug: {eq: "footer-event-box-2"}) {
        shortText
        content {
          childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
          }
        }
      }
      footer_event_3: contentfulGlobalContent(slug: {eq: "footer-event-box-3"}) {
        shortText
        content {
          childMarkdownRemark {
              html
              rawMarkdownBody
              htmlAst
          }
        }
      }
    }
  `}
      render={data => {
        const footerEvent1 = data.footer_event_1;
        const footerEvent2 = data.footer_event_2;
        const footerEvent3 = data.footer_event_3;
        const footerEventTitle = data.footer_event_title;
        return (
            !data ? null : (
              <>
              {!footerEventTitle ? null : <FooterEventsTitle>{footerEventTitle.shortText}  </FooterEventsTitle>}
              <FooterEvents>
                {!footerEvent1 ? null :
                  <FooterEventBox>
                    <div className="title">{footerEvent1.shortText}</div>
                    { footerEvent1.content?.childMarkdownRemark && <div className="description" dangerouslySetInnerHTML={{__html: footerEvent1.content.childMarkdownRemark.html}} /> }
                  </FooterEventBox>
                }
                {!footerEvent2 ? null :
                  <FooterEventBox>
                    <div className="title">{footerEvent2.shortText}</div>
                    { footerEvent2.content?.childMarkdownRemark && <div className="description" dangerouslySetInnerHTML={{__html: footerEvent2.content.childMarkdownRemark.html}} /> }
                  </FooterEventBox>
                }
                {!footerEvent3 ? null :
                  <FooterEventBox>
                    <div className="title">{footerEvent3.shortText}</div>
                    { footerEvent3.content?.childMarkdownRemark && <div className="description" dangerouslySetInnerHTML={{__html: footerEvent3.content.childMarkdownRemark.html}} /> }
                  </FooterEventBox>
                }
              </FooterEvents>
              </>
            )

        )
      }}
    />)
}

export default FooterEventsContainer
